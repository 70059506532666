﻿import {Injectable} from "@angular/core";
import {BaseApiDataService} from "./base-api-data.service";
import {Observable} from "rxjs";
import {AccountModels} from "../models/api/account-models";
import {JwtService} from "./jwt.service";
import {ChangePassword} from "../models/api/command/change-password";
import {ChangeEmail} from "../models/api/command/change-email";
import { ChangePhones } from "../models/api/command/change-phones";
import { ChangeBillDeliveryType } from "../models/api/command/change-bill-delivery-type";
import { Arrears } from "../models/api/command/arrears";
import { SendArrearsEmail } from "../models/api/command/send-arrears-email";

@Injectable()
export class AccountDataService
{
  constructor(private baseApiDataService: BaseApiDataService,
              private jwtService: JwtService)
  { }

  public ChangeBillDeliveryType(changeBillDeliveryTypeP: ChangeBillDeliveryType): Observable<AccountModels.ChangeBillDeliveryType>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`account/customer/${customer_id}/change-bill-delivery-type`, changeBillDeliveryTypeP);
  }

  public ChangeEmail(changeEmailModelP: ChangeEmail): Observable<AccountModels.ChangeEmail>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`account/customer/${customer_id}/change-email`, changeEmailModelP);
  }

  public ChangePassword(changePasswordModelP: ChangePassword): Observable<AccountModels.ChangePassword>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`account/customer/${customer_id}/change-password`, changePasswordModelP);
  }

  public ChangePhones(changePhonesModelP: ChangePhones): Observable<AccountModels.ChangePhones>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`account/customer/${customer_id}/change-phones`, changePhonesModelP);
  }

  public Arrears(arrearsModelP: Arrears, premise_id: string): Observable<AccountModels.Arrears> {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`account/customer/${customer_id}/arrears/${premise_id}/create-arrears`, arrearsModelP);
  }

  public GetArrears(premiseId: string): Observable<AccountModels.Arrears>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;
    return this.baseApiDataService.MakeAuthApiGetRequest(`account/customer/${customer_id}/arrears/${premiseId}`);
  }

  public SendArrearsEmail(emailDetails: SendArrearsEmail, premiseId: string): Observable<AccountModels.SendArrearsEmail> {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`account/customer/${customer_id}/arrears/${premiseId}/email-arrears-details`, emailDetails);
  }
}
