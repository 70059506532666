import {Injectable} from "@angular/core";
import {BaseApiDataService} from "./base-api-data.service";
import {Observable} from "rxjs";
import {OutageModels} from "../models/api/outage-models";

@Injectable()
export class OutageClueDataService
{
  constructor(private baseApiDataService: BaseApiDataService)
  { }

  public GetAllOutageClues(): Observable<OutageModels.GetAllOutageClues>
  {
    return this.baseApiDataService.MakeAuthApiGetRequest(`outage/outageclues/all`);
  }
}
