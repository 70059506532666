import {Component, OnInit, ViewChild} from '@angular/core';
import {PreAuthorizedAccountQueryResult} from "../../models/api/query-result/pre-authorized-account-query-result";
import {PreAuthorizedAccountDataService} from "../../services/pre-authorized-account-data.service";
import {Router} from "@angular/router";
import {RemovePreAuthorizedAccountModalComponent} from "../remove-pre-authorized-account-modal/remove-pre-authorized-account-modal.component";
import {CustomerAccountWithPreAuthQueryResult} from "../../models/api/query-result/customer-account-pre-auth-query-result";
import { Observable, forkJoin } from "rxjs";
import { CustomerAccountDataService } from "../../services/customer-account-data.service";
import {PreAuthorizedAccountRemove} from "../../models/api/command/pre-authorized-account-remove";
import {Title} from "@angular/platform-browser";
import { PreAuthorizedAccountService } from "../../services/pre-authorized-account.service";
import { PreAuthorizedAccountConfirmationTypes } from "../../models/app/enum/pre-authorized-account-confirmation-types";
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'mp-pre-authorized-payments',
  templateUrl: './manage-pre-authorized-accounts.component.html',
  styleUrls: ['./manage-pre-authorized-accounts.component.scss']
})
export class ManagePreAuthorizedAccountsComponent implements OnInit
{

  @ViewChild(RemovePreAuthorizedAccountModalComponent)
  public RemovePreAuthorizedAccountModal: RemovePreAuthorizedAccountModalComponent;

  constructor(private preAuthorizedAccountDataService: PreAuthorizedAccountDataService,
              private preAuthorizedAccountService: PreAuthorizedAccountService,
              private customerAccountDataService: CustomerAccountDataService,
              private router: Router,
              private titleService: Title)
  { }

  public LoadingApiData: boolean;
  public PreAuthorizedAccounts: PreAuthorizedAccountQueryResult[];
  public Premises: CustomerAccountWithPreAuthQueryResult[];
  public ErrorMessages: string[];
  public SuccessMessage: string;
  public NsfAccount: boolean = false;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Manage Pre-Authorized Payment Plans');
    this.ErrorMessages = [];

    this.LoadApiData()
  }

  public LaunchRemovePreAuthorizedAccountComponent(accountToRemoveP: PreAuthorizedAccountQueryResult)
  {
    this.RemovePreAuthorizedAccountModal.ShowModal(accountToRemoveP);
  }

  public ManagePreAuthAccount(preAuthorizedAccountP: PreAuthorizedAccountQueryResult): void
  {
    this.router.navigate(['/pre-authorized-account', preAuthorizedAccountP.PremiseIDs[0]]);
  }

  public AddAnotherAccount(): void
  {
    this.router.navigate(['/pre-authorized-account']);
  }

  private LoadApiData(): void
  {
    this.LoadingApiData = true;

    const api_data_calls = forkJoin(
      this.preAuthorizedAccountDataService.GetAllForCustomer(),
      this.customerAccountDataService.GetWithPreAuthForCustomer()
    );

    api_data_calls.subscribe((resultsP) => {
      this.LoadingApiData = false;

      this.PreAuthorizedAccounts = resultsP[0].PreAuthorizedAccounts;
      this.Premises = resultsP[1].CustomerAccounts; 

      //Do any of this user's accounts have NSF flagged?
      this.Premises.forEach(element => {
        if(element.NsfChequeCounter == true)
        this.NsfAccount = true;
      });
    }, () => {
      this.LoadingApiData = false;
    });   
  }

  public RemovePreAuthorizedAccount(accountToRemoveP: PreAuthorizedAccountQueryResult): void
  {
    this.LoadingApiData = true;
    this.SuccessMessage = "";
    this.ErrorMessages = [];

    const removal_model: PreAuthorizedAccountRemove = {
      CustomerID: accountToRemoveP.CustomerID,
      PremiseIDs: accountToRemoveP.PremiseIDs,
      BankNumber: accountToRemoveP.BankNumber,
      BranchNumber: accountToRemoveP.BranchNumber,
      AccountNumber: accountToRemoveP.AccountNumber,
      AuthorizedByCustomer: true
    };

    this.preAuthorizedAccountDataService.Remove(removal_model).subscribe(resultP => {

      if (resultP.IsSuccess)
      {
        this.preAuthorizedAccountService.CachePreAuthorizedAccountConfirmation(PreAuthorizedAccountConfirmationTypes.Remove, removal_model, resultP.Result);
        this.router.navigate(['/pre-authorized-account-confirmation']);
        return;
      }
      else
      {
        this.LoadingApiData = false;
        this.ErrorMessages = resultP.ErrorMessages;
      }
    }, () => {
      this.LoadingApiData = false;
      this.ErrorMessages.push("Could not connect to the server at this time. Please try again or contact Maritime Electric if the problem persists.")
    });
  }
}
