import {Injectable} from "@angular/core";
import {BaseApiDataService} from "./base-api-data.service";
import {JwtService} from "./jwt.service";
import {Observable} from "rxjs";
import {CustomerTransactionModels} from "../models/api/customer-transaction-models";

@Injectable()
export class CustomerTransactionDataService
{
  constructor(private baseApiDataService: BaseApiDataService,
              private jwtService: JwtService)
  { }

  public GetAllForCustomerAccount(premiseIdP: string): Observable<CustomerTransactionModels.GetAllForCustomerAccount>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiGetRequest(`customer-transactions/customer/${customer_id}/premise/${premiseIdP}/all`);
  }
}
