﻿import { Component, OnInit } from '@angular/core';
import { CustomerQueryResult } from "../../models/api/query-result/customer-query-result";
import { CustomerDataService } from "../../services/customer-data.service";
import { JwtService } from "../../services/jwt.service";
import { Title } from "@angular/platform-browser";
import { ServiceOrderHoldQueryResult } from 'app/models/api/query-result/service-order-hold-query-result';
import { ServiceOrderQueryResult } from 'app/models/api/query-result/service-order-query-result';
import { ServiceOrderStatusCodes } from 'app/models/app/enum/service-order-status-codes';

@Component({
  selector: 'mp-service-requests',
  templateUrl: './service-requests.component.html',
  styleUrls: ['./service-requests.component.scss']
})
export class ServiceRequestsComponent implements OnInit {
  constructor(private customerDataService: CustomerDataService,
    private jwtService: JwtService,
    private titleService: Title)
  { }

  public Customer: CustomerQueryResult;
  public IsAMultiCustomerAccount: boolean;
  public LoadingApiData: boolean;
  public ServerConnectionError: boolean;

  public ngOnInit(): void {
    this.titleService.setTitle('Service Requests');
    this.LoadingApiData = true;
    this.IsAMultiCustomerAccount = this.jwtService.CurrentAuthTokenPayload.IsAMultiCustomerAccount;

    this.customerDataService.GetHydratedById().subscribe((resultP) => {
      this.LoadingApiData = false;
      this.Customer = resultP.Customer;
    }, () => {
      this.LoadingApiData = false;
      this.ServerConnectionError = true;
    })
  }

  public GetServiceOrderStatus(serviceOrderP: ServiceOrderQueryResult): string
  {
    let next_hold: ServiceOrderHoldQueryResult = this.GetNextPriorityHold(serviceOrderP);
    if (next_hold !== null)
    {
      return next_hold.OrderStatusCode >= ServiceOrderStatusCodes.ClosedAllWorkCompleted ? 'Closed' : 'Active';
    }

    return 'Active';
  }

  public GetHoldsWaiting(serviceOrderP: ServiceOrderQueryResult): ServiceOrderHoldQueryResult[]
  {
    let holds_waiting: ServiceOrderHoldQueryResult[] = [];
    for (const hold of serviceOrderP.ServiceOrderHolds)
    {
      if (hold.HoldDescription !== null)
      {
        holds_waiting.push(hold);
      }
    }

    return holds_waiting;
  }

  public GetClassForStep(stepP: number, serviceOrderP: ServiceOrderQueryResult): string
  {
    let next_hold: ServiceOrderHoldQueryResult = this.GetNextPriorityHold(serviceOrderP);
    if (next_hold !== null)
    {
      switch (stepP)
      {
        case 1:
          return this.GetClassForHold(ServiceOrderStatusCodes.InquiryOnly, ServiceOrderStatusCodes.ReadyForSurveying, next_hold);
        case 2:
          return this.GetClassForHold(ServiceOrderStatusCodes.AssignedToSurvey, ServiceOrderStatusCodes.SurveyCompleted, next_hold);
        case 3:
          return this.GetClassForHold(ServiceOrderStatusCodes.ReadyForLineWork, ServiceOrderStatusCodes.IssuesSomsNoBillNeeded, next_hold);
        case 4:
          return this.GetClassForHold(ServiceOrderStatusCodes.ClosedAllWorkCompleted, ServiceOrderStatusCodes.ClosedSiteVisitNoWork, next_hold);
      }
    }

    return '';
  }

  public GetMessageForStep(stepP: number, serviceOrderP: ServiceOrderQueryResult): string
  {
    let next_hold: ServiceOrderQueryResult = this.GetNextPriorityHold(serviceOrderP);
    if (next_hold !== null)
    {
      switch (stepP)
      {
        case 1:
          return this.GetMessageForHold(ServiceOrderStatusCodes.InquiryOnly, ServiceOrderStatusCodes.ReadyForSurveying, next_hold);
        case 2:
          return this.GetMessageForHold(ServiceOrderStatusCodes.AssignedToSurvey, ServiceOrderStatusCodes.SurveyCompleted, next_hold);
        case 3:
          return this.GetMessageForHold(ServiceOrderStatusCodes.ReadyForLineWork, ServiceOrderStatusCodes.IssuesSomsNoBillNeeded, next_hold);
        case 4:
          return this.GetMessageForHold(ServiceOrderStatusCodes.ClosedAllWorkCompleted, ServiceOrderStatusCodes.ClosedSiteVisitNoWork, next_hold);
      }
    }

    return '';
  }

  public DoesStepHaveHold(stepP: number, serviceOrderP: ServiceOrderQueryResult): boolean
  {
    return this.GetClassForStep(stepP, serviceOrderP) === 'hold';
  }

  private GetNextPriorityHold(serviceOrderP: ServiceOrderQueryResult): ServiceOrderHoldQueryResult
  {
    let next_hold: ServiceOrderHoldQueryResult = null;

    // first, try to get a hold with HoldDescription set
    for (const hold of serviceOrderP.ServiceOrderHolds)
    {
      if (hold.HoldDescription !== null && (next_hold === null || hold.OrderStatusCode < next_hold.OrderStatusCode))
      {
        next_hold = hold;
      }
    }

    // if no HoldDescription is set in any, get any other record
    if (next_hold === null)
    {
      for (const hold of serviceOrderP.ServiceOrderHolds)
      {
        if (next_hold === null || hold.OrderStatusCode < next_hold.OrderStatusCode)
        {
          next_hold = hold;
        }
      }
    }

    return next_hold;
  }

  private GetClassForHold(minP: ServiceOrderStatusCodes, maxP: ServiceOrderStatusCodes, serviceOrderHoldP: ServiceOrderHoldQueryResult): string
  {
    if (serviceOrderHoldP.OrderStatusCode >= minP)
    {
      if (serviceOrderHoldP.OrderStatusCode <= maxP && serviceOrderHoldP.HoldDescription !== null)
      {
        return 'hold';
      }

      return serviceOrderHoldP.OrderStatusCode > maxP ? 'active proceed' : 'active';
    }

    return '';
  }

  private GetMessageForHold(minP: ServiceOrderStatusCodes, maxP: ServiceOrderStatusCodes, serviceOrderHoldP: ServiceOrderHoldQueryResult): string
  {
    if (serviceOrderHoldP.OrderStatusCode >= minP)
    {
      if (serviceOrderHoldP.OrderStatusCode <= maxP && serviceOrderHoldP.HoldDescription !== null)
      {
        return serviceOrderHoldP.HoldDescription;
      }
    }

    return '';
  }
}
