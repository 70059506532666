﻿import { Component, OnInit } from '@angular/core';
import { AccountDataService } from "../../services/account-data.service";
import { NgForm } from "@angular/forms";
import { ChangePassword } from "../../models/api/command/change-password";
import { Router } from "@angular/router";
import { UpdateAccountService } from "../../services/update-account.service";
import { Title } from "@angular/platform-browser";
import { UserAuthenticationService } from "../../services/user-authentication.service";
import { environment } from 'environments/environment';

@Component({
  selector: 'mp-update-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private userAuthenticationService: UserAuthenticationService,
    private accountDataService: AccountDataService,
    private router: Router,
    private updateAccountService: UpdateAccountService,
    private titleService: Title)
  { }

  public ErrorMessage: string;
  public SubmittingChange: boolean;
  public ServerConnectionError: boolean;
  public RegistrationErrorMessages: string[];
  public ResetPassword: boolean = false;

  public ngOnInit(): void {
    this.titleService.setTitle('Update Account - Password');
    this.ResetPassword = this.userAuthenticationService.UserRequiresPasswordUpdate();
    this.SubmittingChange = false;
  }

  public SubmitPasswordChange(formP: NgForm): void {
    this.ErrorMessage = "";
    this.ServerConnectionError = false;

    if (formP.invalid) {
      return;
    }

    const change_password_model: ChangePassword = formP.value;
    this.SubmittingChange = true;

    this.accountDataService.ChangePassword(change_password_model).subscribe(resultP => {
      this.SubmittingChange = false;

      if (resultP.IsSuccess) {
        this.updateAccountService.AccountUpdatedMessage = "Password changed successfully. Please login with new password.";
        this.userAuthenticationService.LogOut();
        return;
      }

      this.RegistrationErrorMessages = resultP.ErrorMessages;

      this.ErrorMessage = "Unable to change password. Please ensure current password is correct.";
    }, () => {
      this.SubmittingChange = false;
      this.ServerConnectionError = true;
      this.RegistrationErrorMessages = [];
    })
  }
}
