import { Component, OnInit } from '@angular/core';
import {CustomerQueryResult} from "../../models/api/query-result/customer-query-result";
import {CustomerAccountQueryResult} from "../../models/api/query-result/customer-account-query-result";
import {DisconnectNoticeQueryResult} from "../../models/api/query-result/disconnect-notice-query-result";
import {DisconnectNoticeDataService} from "../../services/disconnect-notice-data.service";
import {CustomerDataService} from "../../services/customer-data.service";
import {PdfService} from "../../services/pdf.service";
import {ActivatedRoute} from "@angular/router";
import {CustomerAccountDataService} from "../../services/customer-account-data.service";
import {Observable, forkJoin} from "rxjs";
import {MonthScopedRequest} from "../../models/api/query/month-scoped-request";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-disconnect-notices',
  templateUrl: './disconnect-notices.component.html',
  styleUrls: ['./disconnect-notices.component.scss']
})
export class DisconnectNoticesComponent implements OnInit
{
  constructor(private customerDataService: CustomerDataService,
              private customerAccountDataService: CustomerAccountDataService,
              private disconnectNoticeDataService: DisconnectNoticeDataService,
              private pdfService: PdfService,
              private activatedRoute: ActivatedRoute,
              private titleService: Title)
  { }

  public Customer: CustomerQueryResult;
  public CustomerAccount: CustomerAccountQueryResult;
  public DisconnectNotices: DisconnectNoticeQueryResult[];
  public LoadingApiData: boolean;
  public PdfRequestErrorMessage: string;
  public ServerConnectionError: boolean;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Disconnect Notices');
    this.LoadingApiData = true;

    this.activatedRoute.params.subscribe(paramsP => {
      const premise_id = paramsP['premiseId'];

      const api_data_requests = forkJoin(
        this.customerDataService.GetById(),
        this.customerAccountDataService.GetCustomerAccount(premise_id),
        this.disconnectNoticeDataService.GetAllForCustomerAccount(premise_id)
      );

      api_data_requests.subscribe(resultsP => {
        this.LoadingApiData = false;

        this.Customer = resultsP[0].Customer;
        this.CustomerAccount = resultsP[1].CustomerAccount;
        this.DisconnectNotices = resultsP[2].DisconnectNotices;
      }, () => {
        this.LoadingApiData = false;
        this.ServerConnectionError = true;
      })
    })
  }

  public DownloadPdf(premiseIdP: string, yearP: string, monthP: string): void
  {
    this.ServerConnectionError = false;
    this.PdfRequestErrorMessage = "";

    const month_scoped_request: MonthScopedRequest = {
      Year: parseInt(yearP),
      Month: parseInt(monthP)
    };

    this.disconnectNoticeDataService.GetPdfFileTokenForPeriod(premiseIdP, month_scoped_request).subscribe((resultP) => {

      if (resultP.IsSuccess)
      {
        this.pdfService.DownloadPdf(resultP.PdfUrl);
      }
    }, () => {
      this.ServerConnectionError = true;
    })
  }
}
