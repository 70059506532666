﻿import { Component, OnInit } from '@angular/core';
import {OutageClueQueryResult} from "../../models/api/query-result/outage-clue-query-result";
import {PremiseDataService} from "../../services/premise-data.service";
import {OutageClueDataService} from "../../services/outage-clue-data.service";
import {OutageDataService} from "../../services/outage-data.service";
import {PremiseQueryResult} from "../../models/api/query-result/premise-query-result";
import {Observable, forkJoin} from "rxjs";
import {JwtService} from "../../services/jwt.service";
import {NgForm} from "@angular/forms";
import {ReportOutage} from "../../models/api/command/report-outage";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-report-an-outage',
  templateUrl: './report-an-outage.component.html',
  styleUrls: ['./report-an-outage.component.scss']
})
export class ReportAnOutageComponent implements OnInit
{
  constructor(private premiseDataService: PremiseDataService,
              private outageClueDataService: OutageClueDataService,
              private outageDataService: OutageDataService,
              private jwtService: JwtService,
              private router: Router,
              private titleService: Title)
  { }

  public FetchingApiData: boolean;
  public OutageClues: OutageClueQueryResult[];
  public Premises: PremiseQueryResult[];
  public ReportOutageErrorMessages: string[];
  public ServerConnectionError: boolean;
  public SubmittingReportOutage: boolean;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Report an Outage');
    this.SubmittingReportOutage = false;
    this.FetchingApiData = true;

    // Join our two api requests together so they can be executed in parallel while awaited for at once.
    // https://github.com/Reactive-Extensions/RxJS/blob/master/doc/api/core/operators/forkjoin.md
    const api_calls = forkJoin(this.outageClueDataService.GetAllOutageClues(), this.premiseDataService.GetForCustomer());

    api_calls.subscribe((resultsP) => {
      this.FetchingApiData = false;

      this.OutageClues = resultsP[0].OutageClues;
      this.Premises = resultsP[1].Premises;
    }, (errorP) => {
      this.FetchingApiData = false;
      this.ServerConnectionError = true;
    })
  }

  public ReportOutage(reportOutageFormP: NgForm): void
  {
    this.ResetServerErrorMessaging();

    if (reportOutageFormP.invalid)
    {
      return;
    }

    const report_outage_model: ReportOutage = reportOutageFormP.value;
    report_outage_model.CustomerID = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    if (this.Premises.length === 1)
    {
      report_outage_model.PremiseID = this.Premises[0].ID;
    }

    this.SubmittingReportOutage = true;

    this.outageDataService.ReportAnOutage(report_outage_model).subscribe((resultP) => {
      this.SubmittingReportOutage = false;

      // Navigate to the success page where the appropriate messaging will be displayed
      if (resultP.IsSuccess)
      {
        this.router.navigate(["/outage-reported"]);
        return;
      }

      // Add any server driven error messaging for display
      if (resultP.IsFailure)
      {
        this.ReportOutageErrorMessages = this.ReportOutageErrorMessages.concat(resultP.ErrorMessages);
      }
    }, (errorP) => {
      this.SubmittingReportOutage = false;
      this.ServerConnectionError = true;
    });
  }

  private ResetServerErrorMessaging(): void
  {
    this.ServerConnectionError = false;
    this.ReportOutageErrorMessages = [];
  }
}
