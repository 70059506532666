import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CustomerAccountWithPreAuthQueryResult} from "../../models/api/query-result/customer-account-pre-auth-query-result";
import {PreAuthorizedAccountQueryResult} from "../../models/api/query-result/pre-authorized-account-query-result";
import {ModalDirective} from "ngx-bootstrap";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'mp-remove-pre-authorized-account-modal',
  templateUrl: './remove-pre-authorized-account-modal.component.html',
  styleUrls: ['./remove-pre-authorized-account-modal.component.scss']
})
export class RemovePreAuthorizedAccountModalComponent implements OnInit
{
  @ViewChild('removePreAuthorizedAccountModal')
  public RemovePreAuthorizedAccountModal: ModalDirective;

  @Input('premises')
  public Premises: CustomerAccountWithPreAuthQueryResult[];

  @Output('accountRemovalConfirmed')
  public AccountRemovalConfirmedEvent = new EventEmitter<PreAuthorizedAccountQueryResult>();

  constructor()
  { }

  public AccountHasBudgetBillingPremises: boolean;
  public AssociatedPremises: CustomerAccountWithPreAuthQueryResult[];
  public PreAuthorizedAccount: PreAuthorizedAccountQueryResult;

  public ngOnInit(): void
  { }

  public ConfirmAccountRemoval(formP: NgForm): void
  {
    if (formP.invalid)
    {
      return;
    }

    this.AccountRemovalConfirmedEvent.emit(this.PreAuthorizedAccount);
    this.HideModal();
  }

  public HideModal(): void
  {
    this.PreAuthorizedAccount = null;

    this.RemovePreAuthorizedAccountModal.hide();
  }

  public ShowModal(preAuthorizedAccountP: PreAuthorizedAccountQueryResult): void
  {
    this.PreAuthorizedAccount = preAuthorizedAccountP;
    // Get everything set up for the user
    this.AssociatedPremises = this.PreAuthorizedAccount.PremiseIDs.map(premiseIdP => {
      return this.Premises.find(x => x.PremiseID === premiseIdP);
    });

    this.AccountHasBudgetBillingPremises = this.AssociatedPremises.some(x => x.BudgetBillingEnabled);

    this.RemovePreAuthorizedAccountModal.show();
  }
}
