import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { FormsService } from "../../services/forms.service";
import { FormModels } from "../../models/api/form-models";
import { Router } from "@angular/router";

@Component({
  selector: 'mp-deferred-payments',
  templateUrl: './deferred-payments.component.html',
  styleUrls: ['./deferred-payments.component.scss']
})
export class DeferredPaymentsComponent implements OnInit {

  constructor(private formsService: FormsService,
    private router: Router) { }

    public DeferredPaymentsErrorMessages: string[];
    public ServerConnectionError: boolean;
    public ActiveServerRequest: boolean;

    updateAccepted(input: HTMLInputElement, button: HTMLInputElement): void {
    if (input.checked)
      button.disabled = false;
    else
      button.disabled = true;
    }

    ngOnInit() {
      this.ResetServerFormErrors();
    }public SendToApi(formP: NgForm): void {

      //If form is invalid, we exit.
      if (formP.invalid) {
        return;
      }
  
      //Send Form Results to FormsService
      this.formsService.DeferredPayments(formP.value).subscribe((deferredPaymentsResultP: FormModels.DeferredPayments) => {
        this.ActiveServerRequest = false;
  
        if (deferredPaymentsResultP.IsSuccess) {
          this.router.navigate(['/forms/success']);
        }
  
        if (deferredPaymentsResultP.IsFailure) {
          this.router.navigate(['/forms/error']);
        }
  
        this.DeferredPaymentsErrorMessages = deferredPaymentsResultP.ErrorMessages;
  
      }, (errorP) => {
        this.ActiveServerRequest = false;
        this.ServerConnectionError = true;
        this.router.navigate(['/forms/error']);
        return;
      });
    }
  
    private ResetServerFormErrors(): void {
      this.DeferredPaymentsErrorMessages = [];
      this.ServerConnectionError = false;
    }
  }