import {Attribute, Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from "@angular/forms";

@Directive({
  selector: '[mpEqualsValidator][ngModel]',
  providers:[
    { provide: NG_VALIDATORS, useExisting: EqualsValidatorDirective, multi: true }
  ]
})
export class EqualsValidatorDirective implements Validator
{
  constructor(@Attribute('mpEqualsValidator') public validateEqual: string) {}

  public validate(controlP: AbstractControl): { [key: string]: any }
  {
    const control_value = controlP.value;
    const comparator_control_value = controlP.root.get(this.validateEqual);

    if(comparator_control_value && control_value !== comparator_control_value.value)
    {
      return {
        mpEqualsValidator: true
      }
    }

    return null;
  }
}
