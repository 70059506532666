import {Injectable} from "@angular/core";
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {UserAuthenticationService} from "../../../services/user-authentication.service";

@Injectable()
export class AuthGuard implements CanActivate
{
  constructor(private userAuthenticationService: UserAuthenticationService,
              private router: Router)
  { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean
  {
    if (!this.userAuthenticationService.UserIsLoggedIn())
    {
      this.router.navigate(['/unauthenticated/login']);
      return false;
    }

    if (state.url !== '/account/change-password' && this.userAuthenticationService.UserRequiresPasswordUpdate())
    {
      this.router.navigate(['/account/change-password']);
      return false;
    }

    return true;
  }
}
