﻿import { Component, OnInit } from '@angular/core';
import { CustomerQueryResult } from "../../models/api/query-result/customer-query-result";
import { CustomerDataService } from "../../services/customer-data.service";
import { JwtService } from "../../services/jwt.service";
import { BillDataService } from "../../services/bill-data.service";
import { PdfService } from "../../services/pdf.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'mp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(private customerDataService: CustomerDataService,
    private jwtService: JwtService,
    private billDataService: BillDataService,
    private pdfService: PdfService,
    private titleService: Title)
  { }

  public Customer: CustomerQueryResult;
  public IsAMultiCustomerAccount: boolean;
  public LoadingApiData: boolean;
  public ServerConnectionError: boolean;

  public ngOnInit(): void {
    this.titleService.setTitle('Home');
    this.LoadingApiData = true;
    this.IsAMultiCustomerAccount = this.jwtService.CurrentAuthTokenPayload.IsAMultiCustomerAccount;

    this.customerDataService.GetHydratedById().subscribe((resultP) => {
      this.LoadingApiData = false;
      this.Customer = resultP.Customer;
    }, () => {
      this.LoadingApiData = false;
      this.ServerConnectionError = true;
    })
  }

  public OpenCurrentBillPdfForPremise(premiseIdP: string): void {
    this.billDataService.GetTokenForCurrent(premiseIdP).subscribe((resultP) => {

      if (resultP.IsSuccess) {
        this.pdfService.DownloadPdf(resultP.PdfUrl);
      }
    }, (errorP) => { })
  }
}
