﻿import { Component, OnInit } from '@angular/core';
import {CustomerDataService} from "../../services/customer-data.service";
import {CustomerQueryResult} from "../../models/api/query-result/customer-query-result";
import {Observable, forkJoin} from "rxjs";
import {PhoneTypeDataService} from "../../services/phone-type-data.service";
import {PhoneTypeQueryResult} from "../../models/api/query-result/phone-type-query-result";
import {UpdateAccountService} from "../../services/update-account.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-update-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit
{
  constructor(private customerDataService: CustomerDataService,
              private phoneTypeDataService: PhoneTypeDataService,
              private updateAccountService: UpdateAccountService,
              private titleService: Title)
  { }

  public AccountUpdatedMessage: string;
  public Customer: CustomerQueryResult;
  public LoadingApiData: boolean;
  public ServerConnectionError: boolean;

  private m_PhoneTypes: PhoneTypeQueryResult[];

  public ngOnInit(): void
  {
    this.titleService.setTitle("Update Account");
    this.AccountUpdatedMessage = this.updateAccountService.AccountUpdatedMessage;
    this.updateAccountService.ResetAccountUpdatedMessage();
    this.LoadingApiData = true;

    const api_data_calls = forkJoin(
      this.customerDataService.GetById(),
      this.phoneTypeDataService.GetAll()
    );

    api_data_calls.subscribe(resultsP => {
      this.LoadingApiData = false;

      this.Customer = resultsP[0].Customer;
      this.m_PhoneTypes = resultsP[1].PhoneTypes;

      this.customerDataService.AddPhoneTypeNamesToCustomer(this.Customer, this.m_PhoneTypes);
    }, () => {
      this.LoadingApiData = false;
      this.ServerConnectionError = true;
    })
  }
}
