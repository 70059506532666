import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mp-un-authenticated-footer',
  templateUrl: './un-authenticated-footer.component.html',
  styleUrls: ['./un-authenticated-footer.component.scss']
})
export class UnAuthenticatedFooterComponent implements OnInit
{
  constructor()
  { }

  public CurrentYear: number;

  public ngOnInit()
  {
    this.CurrentYear = new Date().getFullYear()
  }
}
