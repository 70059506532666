import { Component, OnInit } from '@angular/core';
import {CustomerDataService} from "../../services/customer-data.service";
import {CustomerAccountDataService} from "../../services/customer-account-data.service";
import {PreAuthorizedAccountService} from "../../services/pre-authorized-account.service";
import {PreAuthorizedAccountConfirmationTypes} from "../../models/app/enum/pre-authorized-account-confirmation-types";
import {CustomerQueryResult} from "../../models/api/query-result/customer-query-result";
import {CustomerAccountQueryResult} from "../../models/api/query-result/customer-account-query-result";
import {Router} from "@angular/router";
import {Observable, forkJoin} from "rxjs";
import { CustomerAccountWithPreAuthQueryResult } from "../../models/api/query-result/customer-account-pre-auth-query-result";
import {PreAuthorizedAccountConfirmationModel} from "../../models/app/pre-authorized-account-confirmation-model";

@Component({
  selector: 'mp-pre-authorized-payment-confirmation',
  templateUrl: './pre-authorized-account-confirmation.component.html',
  styleUrls: ['./pre-authorized-account-confirmation.component.scss']
})
export class PreAuthorizedAccountConfirmationComponent implements OnInit
{
  constructor(private preAuthorizedAccountService: PreAuthorizedAccountService,
              private customerDataService: CustomerDataService,
              private customerAccountDataService: CustomerAccountDataService,
              private router: Router)
  { }

  public AssociatedCustomerAccounts: CustomerAccountWithPreAuthQueryResult[];
  public Customer: CustomerQueryResult;
  public CustomerAccounts: CustomerAccountQueryResult[];
  public DataLoadingError: boolean;
  public LoadingApiData: boolean;
  public PreAuthorizedAccountConfirmation: PreAuthorizedAccountConfirmationModel;
  public PreAuthorizedAccountConfirmationTypes = PreAuthorizedAccountConfirmationTypes;

  public ngOnInit(): void
  {
    this.LoadingApiData = true;
    this.PreAuthorizedAccountConfirmation = this.preAuthorizedAccountService.CachedPreAuthorizedAccountConfirmationModel;

    if (!this.PreAuthorizedAccountConfirmation || !this.PreAuthorizedAccountConfirmation.Type)
    {
      this.router.navigate(['/manage-pre-authorized-accounts']);
      return;
    }

    this.LoadDataForAddOrUpdate();
  }

  private LoadDataForAddOrUpdate(): void
  {
    const api_data_calls = forkJoin(
      this.customerDataService.GetById(),
      this.customerAccountDataService.GetWithPreAuthForCustomer()
    );

    api_data_calls.subscribe(resultsP => {
      this.LoadingApiData = false;
      this.Customer = resultsP[0].Customer;
      this.CustomerAccounts = resultsP[1].CustomerAccounts;

      this.AssociatedCustomerAccounts = this.PreAuthorizedAccountConfirmation.Submission.PremiseIDs.map(premiseIdP => this.CustomerAccounts.find(customerAccountP => customerAccountP.PremiseID === premiseIdP));
    }, () => {
      this.LoadingApiData = false;
      this.DataLoadingError = true;
    })
  }
}
