﻿import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable, Subject} from "rxjs";
import {AuthTokenPayload} from "../models/api/auth-token-payload";
import {Router} from "@angular/router";
import * as Cookies from "js-cookie";
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class JwtService
{
  constructor(private router: Router, private jwtHelper: JwtHelperService)
  {
    this.m_AuthTokenPayloadUpdatesSubject = new Subject<AuthTokenPayload>();
    this.AuthTokenPayloadUpdates$ = this.m_AuthTokenPayloadUpdatesSubject.asObservable();
  }

  public AuthTokenPayloadUpdates$: Observable<AuthTokenPayload>;
  public get CurrentAuthTokenPayload(): AuthTokenPayload
  {
    if (!this.m_CurrentAuthTokenPayload)
    {
      this.UpdateAuthTokenPayloadUsingLocalStorage();
    }

    return this.m_CurrentAuthTokenPayload;
  }

  private m_AuthTokenPayloadUpdatesSubject: Subject<AuthTokenPayload>;
  private m_CurrentAuthTokenPayload: AuthTokenPayload;

  public AuthTokenIsStillValid(): boolean
  {
    let token = this.LoadTokenFromCookieIfAvailable();

    return !(this.jwtHelper.isTokenExpired(token));
  }

  public UserRequiresPasswordUpdate()
  {
    return this.GetAuthTokenPayloadFromLocalStorage().PasswordRequiresReset;
  }

  public ClearAuthToken(): void
  {
    Cookies.remove(environment.JWTKey, this.GetCookieConfiguration());
    localStorage.removeItem(environment.JWTKey);
    this.m_CurrentAuthTokenPayload = null;
  }

  public SetAuthToken(tokenP: string)
  {
      // Setting a cookie that we can grab onto after the redirect. Safari has issues with cross-domain local storage.
    Cookies.set(environment.JWTKey, tokenP, this.GetCookieConfiguration())
    localStorage.setItem(environment.JWTKey, tokenP);

    this.UpdateAuthTokenPayloadUsingLocalStorage();
  }

  private LoadTokenFromCookieIfAvailable(): string
  {
    let token = Cookies.get(environment.JWTKey);

    if (!token)
    {
      return "";
    }

    // Set the token in local storage, just in case we are redirecting from the public site
    // iframe and do not have it set due to safari issues.
    localStorage.setItem(environment.JWTKey, token);

    return token;
  }

  private GetCookieConfiguration()
  {
    return { domain: this.RemovePortFromHost(window.location.host), path: "/", secure: environment.SecureCookie };
  }

  private UpdateAuthTokenPayloadUsingLocalStorage(): void
  {
    const token_payload = this.GetAuthTokenPayloadFromLocalStorage();

    this.m_CurrentAuthTokenPayload = token_payload;
    this.m_AuthTokenPayloadUpdatesSubject.next(token_payload);
  }

  private GetAuthTokenPayloadFromLocalStorage(): AuthTokenPayload
  {
    // Decode the payload
    const token = localStorage.getItem(environment.JWTKey);

    let token_payload = null;

    if (token)
    {
      token_payload = this.jwtHelper.decodeToken(token);
    }

    if (!token_payload || ! this.AuthTokenIsStillValid())
    {
      this.router.navigate(['/unauthenticated/login']);
      return null;
    }

    // Update the app state with the payload contents
    return {
      CustomerID: token_payload.CustomerID,
      Email: token_payload.Email,
      // Have to evaluate the string as jwt claims must be strings
      IsAMultiCustomerAccount: token_payload.IsAMultiCustomerAccount === 'True',
      PasswordRequiresReset: token_payload.PasswordRequiresReset === 'True'
    };
  }

  private RemovePortFromHost(hostP: string): string
  {
    return hostP.split(":")[0];
  }
}
