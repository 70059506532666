import { Injectable } from '@angular/core';
import {BaseApiDataService} from "./base-api-data.service";
import {JwtService} from "./jwt.service";
import {Observable} from "rxjs";
import {BillModels} from "../models/api/bill-models";
import {MonthScopedRequest} from "../models/api/query/month-scoped-request";

@Injectable()
export class BillDataService
{
  constructor(private baseApiDataService: BaseApiDataService,
              private jwtService: JwtService)
  { }

  public GetPastBillInformation(premiseIdP: string): Observable<BillModels.GetPastBillInformation>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiGetRequest(`bill-info/customer/${customer_id}/premise/${premiseIdP}/past`);
  }

  // TODO: Review post payload.
  public GetTokenForCurrent(premiseIdP: string): Observable<BillModels.GetTokenForCurrent>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`bill-pdf/customer/${customer_id}/premise/${premiseIdP}/current/generatetoken`, null);
  }

  public GetTokenForPeriod(premiseIdP: string, periodP: MonthScopedRequest): Observable<BillModels.GetTokenForPeriod>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`bill-pdf/customer/${customer_id}/premise/${premiseIdP}/for-period/generatetoken`, periodP);
  }
}
