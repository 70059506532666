import { Component, OnInit } from '@angular/core';
import {OutageDataService} from "../../services/outage-data.service";
import {Router} from "@angular/router";
import {OutageSubmissionResults} from "../../models/api/enum/outage-submission-result";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-outage-reported',
  templateUrl: './outage-reported.component.html',
  styleUrls: ['./outage-reported.component.scss']
})
export class OutageReportedComponent implements OnInit
{
  constructor(private outageDataService: OutageDataService,
              private router: Router,
              private titleService: Title)
  { }

  public OutageSubmissionStatusCode: OutageSubmissionResults;
  public SafetyMessage: string;
  public OutageSubmissionResults = OutageSubmissionResults;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Outage reported successfully');
    this.OutageSubmissionStatusCode = this.outageDataService.OutageSubmissionStatusCode;
    this.SafetyMessage = this.outageDataService.SafetyMessage;

    // They shouldn't be here if they haven't submitted an outage
    if (!this.OutageSubmissionStatusCode)
    {
      this.router.navigate(["/report-an-outage"])
    }
  }
}
