﻿import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { FormsService } from "../../services/forms.service";
import { FormModels } from "../../models/api/form-models";
import { Router } from "@angular/router";

@Component({
  selector: 'mp-streetlight-repair',
  templateUrl: './streetlight-repair.component.html',
  styleUrls: ['./streetlight-repair.component.scss']
})
export class StreetlightRepairComponent implements OnInit {

  constructor(private formsService: FormsService,
    private router: Router) { }

  public StreetlightRepairErrorMessages: string[];
  public ServerConnectionError: boolean;
  public ActiveServerRequest: boolean;

  public StartDate: Date = new Date();
  public MinDate: Date = void 0;

  ngOnInit() {
    this.ResetServerFormErrors();
  }

  public SendToApi(formP: NgForm): void {

    //If form is invalid, we exit.
    if (formP.invalid) {
      return;
    }
    
    //Send Form Results to FormsService
    this.formsService.StreetlightRepair(formP.value).subscribe((streetlightRepairResultP: FormModels.StreetlightRepair) => {
      this.ActiveServerRequest = false;

      if (streetlightRepairResultP.IsSuccess) {
        this.router.navigate(['/forms/success']);
      }

      if (streetlightRepairResultP.IsFailure) {
        this.router.navigate(['/forms/error']);
      }

      this.StreetlightRepairErrorMessages = streetlightRepairResultP.ErrorMessages;

    }, (errorP) => {
      this.ActiveServerRequest = false;
      this.ServerConnectionError = true;
    });
  }

  private ResetServerFormErrors(): void {
    this.StreetlightRepairErrorMessages = [];
    this.ServerConnectionError = false;
  }
}