import {Injectable} from "@angular/core";

@Injectable()
export class UpdateAccountService
{
  constructor()
  { }

  public AccountUpdatedMessage: string;

  public ResetAccountUpdatedMessage(): void
  {
    this.AccountUpdatedMessage = "";
  }
}
