import {FormControl, ValidationErrors} from "@angular/forms";
import {FinancialInstitution} from "./financial-institution";

export function ValidateBankAccount(formControlP: FormControl, validFinancialInstitutionsP: FinancialInstitution[]): ValidationErrors
{
  const inputted_value = parseInt(formControlP.value);

  if (!inputted_value)
  {
    return null;
  }

  // If we have a matching bank account id, we are good and can throw back a null.
  // If the inputted number does not match an existing financial institution ID, pass back a validation error.
  return validFinancialInstitutionsP.some(financialInstitutionP => {
    return financialInstitutionP.ID === inputted_value;
  }) ? null : {
    validFinancialInstitutionNumber: {
      valid: false
    }
  }
}
