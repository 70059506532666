﻿import { Injectable } from "@angular/core";
import { BaseApiDataService } from "./base-api-data.service";
import { DisconnectRequest } from "../models/api/command/disconnect-request";
import { NewServiceRequest } from "../models/api/command/new-service-request";
import { BookPresentation } from "../models/api/command/book-presentation";
import { CommentCard } from "../models/api/command/comment-card";
import { RequestDonation } from "../models/api/command/request-donation";
import { StaycationContest } from "../models/api/command/staycation-contest";
import { DeferredPayments } from "../models/api/command/deferred-payments";
import { StreetlightRepair } from "../models/api/command/streetlight-repair";
import { TreeTrimming } from "../models/api/command/tree-trimming";
import { FormModels } from "../models/api/form-models";
import {Observable} from "rxjs";


@Injectable()
export class FormsService {
  constructor(private baseDataService: BaseApiDataService)
  { }

  public Disconnect(disconnectModelP: DisconnectRequest): Observable<FormModels.Disconnect>
  {
    return this.baseDataService.MakeAuthApiPostRequest('forms/disconnect', disconnectModelP);
  }

  public NewService(newServiceModelP: NewServiceRequest): Observable<FormModels.NewService>
  {
    return this.baseDataService.MakeAuthApiPostRequest('forms/new-service', newServiceModelP);
  }

  public BookPresentation(bookPresentationModelP: BookPresentation): Observable<FormModels.BookPresentation>
  {
    return this.baseDataService.MakeAuthApiPostRequest('forms/presentation', bookPresentationModelP);
  }

  public CommentCard(commentCardModelP: CommentCard): Observable<FormModels.CommentCard>
  {
    return this.baseDataService.MakeAuthApiPostRequest('forms/commentcard', commentCardModelP);
  }

  public RequestDonation(requestDonationModelP: RequestDonation): Observable<FormModels.RequestDonation>
  {
    return this.baseDataService.MakeAuthApiPostRequest('forms/requestdonation', requestDonationModelP);
  }

  public StreetlightRepair(streetlightRepairModelP: StreetlightRepair): Observable<FormModels.StreetlightRepair>
  {
    return this.baseDataService.MakeAuthApiPostRequest('forms/streetlightrepair', streetlightRepairModelP);
  }

  public StaycationContest(staycationContestModelP: StaycationContest): Observable<FormModels.StaycationContest>
  {
    return this.baseDataService.MakeAuthApiPostRequest('forms/staycation-contest', staycationContestModelP);
  }

  public DeferredPayments(deferredPaymentsModelP: DeferredPayments): Observable<FormModels.DeferredPayments>
  {
    return this.baseDataService.MakeAuthApiPostRequest('forms/deferred-payments', deferredPaymentsModelP);
  }

  public TreeTrimming(treeTrimmingModelP: TreeTrimming): Observable<FormModels.TreeTrimming>
   {
    return this.baseDataService.MakeAuthApiPostRequest('forms/treetrimming', treeTrimmingModelP);
  }
}
