import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit
{
  constructor(private titleService: Title)
  { }

  public ngOnInit(): void
  {
    this.titleService.setTitle('Page not found');
  }
}
