﻿import {Component, OnInit} from '@angular/core';
import {CustomerQueryResult} from "../../models/api/query-result/customer-query-result";
import {CustomerDataService} from "../../services/customer-data.service";
import {Router} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent implements OnInit
{
  constructor(private customerDataService: CustomerDataService,
              private router: Router,
              private titleService: Title)
  { }

  public Accounts: CustomerQueryResult[];
  public CustomerIDSelected: string;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Select Active Account');
    this.customerDataService.GetAssociatedCustomers().subscribe((responseP) => {
      this.Accounts = responseP.Customers;
    })
  }

  public SelectAccount(customerIdP: string)
  {
    this.CustomerIDSelected = customerIdP;

    this.customerDataService.SwitchActiveCustomer(customerIdP).subscribe((resultP) => {
      this.CustomerIDSelected = null;

      this.router.navigate(['/']);
    }, () => {
      this.CustomerIDSelected = null;
    })
  }
}
