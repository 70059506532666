import {Pipe, PipeTransform} from "@angular/core";

// This pipe will convert a string or a number to "x"s. Takes 1 input param that can be used to
// show the last x characters as they normally would be
@Pipe({
  name: 'meSecureNumberDisplay'
})
export class MeSecureNumberDisplayPipe implements PipeTransform
{
  public transform(valueP: number|string, ...args: any[]): any
  {
    if (!valueP || (typeof valueP !== "string" && typeof valueP !== "number"))
    {
      return valueP;
    }

    let number_of_shown_digits: number = 0;

    if (args && args[0])
    {
      if (isNaN(args[0]))
      {
        console.error("meSecureNumberDisplay input param must be a number!");
        return "";
      }

      number_of_shown_digits = parseInt(args[0]);
    }

    return this.ConvertAllButLastNumberOfShownDigitsToX(valueP, number_of_shown_digits);
  }

  private ConvertAllButLastNumberOfShownDigitsToX(valueP: string|number, numberOfShownDigitsP): string
  {
    const complete_string = valueP.toString();
    const string_portion_to_keep = complete_string.substring(complete_string.length - numberOfShownDigitsP);
    const string_portion_to_convert = complete_string.substring(0, complete_string.length - numberOfShownDigitsP);

    // Convert to a string, break into an array, replace all elements with "x", and join back together as a string. Add on the amount of characters they wish to keep in tact
    return string_portion_to_convert.split("").map(x =>  "x").join("") + string_portion_to_keep;
  }
}
