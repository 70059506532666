import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'meAddLeadingZeroesToNumberPipe'
})
export class AddingLeadingZeroesToNumberPipe implements PipeTransform
{
  public transform(valueP: string|number, desiredLengthP: number): string
  {
    return PrependLeadingZerosToNumber(valueP, desiredLengthP);
  }
}

export function PrependLeadingZerosToNumber(valueToTransformP: string|number, lengthP: number): string
{
  const value_to_transform = parseInt(valueToTransformP.toString());

  if (!value_to_transform || !lengthP)
  {
    return '';
  }

  let transformed_value = value_to_transform.toString();

  while(transformed_value.length < lengthP)
  {
    transformed_value = "0" + transformed_value;
  }

  return transformed_value;
}
