import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mp-un-authenticated',
  templateUrl: './un-authenticated.component.html',
  styleUrls: ['./un-authenticated.component.scss']
})
export class UnAuthenticatedComponent implements OnInit
{
  constructor()
  { }

  public ngOnInit(): void
  { }
}
