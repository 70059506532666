import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'meBalanceFormat'
})
export class MEBalanceFormatPipe implements PipeTransform
{
  public transform(valueP: number, ...args: any[]): string
  {
    if (valueP >= 0)
    {
      return `$${valueP.toFixed(2)}`;
    }

    return `$${Math.abs(valueP).toFixed(2)}CR`;
  }
}
