import { Injectable } from '@angular/core';
import {BaseApiDataService} from "./base-api-data.service";
import {JwtService} from "./jwt.service";
import {Observable} from "rxjs";
import {PreAuthorizedAccountModels} from "../models/api/pre-authorized-account-models";
import {PreAuthorizedAccountAdd} from "../models/api/command/pre-authorized-account-add";
import {PreAuthorizedAccountUpdate} from "../models/api/command/pre-authorized-account-update";
import {PreAuthorizedAccountRemove} from "../models/api/command/pre-authorized-account-remove";

@Injectable()
export class PreAuthorizedAccountDataService
{
  constructor(private baseApiDataService: BaseApiDataService,
              private jwtService: JwtService)
  { }

  public Add(preAuthAddP: PreAuthorizedAccountAdd): Observable<PreAuthorizedAccountModels.Add>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`pre-authorized-account/customer/${customer_id}/add`, preAuthAddP);
  }

  public GetAllForCustomer(): Observable<PreAuthorizedAccountModels.GetAllForCustomer>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiGetRequest(`pre-authorized-accounts/customer/${customer_id}/all`);
  }

  public GetByCustomerAndPremiseID(premiseIdP: string): Observable<PreAuthorizedAccountModels.GetByCustomerAndPremiseID>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiGetRequest(`pre-authorized-account/customer/${customer_id}/premise/${premiseIdP}`);
  }

  public Remove(preAuthRemoveP: PreAuthorizedAccountRemove): Observable<PreAuthorizedAccountModels.Remove>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`pre-authorized-account/customer/${customer_id}/remove`, preAuthRemoveP);
  }

  public Update(preAuthUpdateP: PreAuthorizedAccountUpdate): Observable<PreAuthorizedAccountModels.Update>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`pre-authorized-account/customer/${customer_id}/update`, preAuthUpdateP);
  }
}
