import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mp-loading-panel',
  templateUrl: './loading-panel.component.html',
  styleUrls: ['./loading-panel.component.scss']
})
export class LoadingPanelComponent implements OnInit
{
  constructor()
  { }

  public ngOnInit(): void
  { }
}
