import { Component, OnInit } from '@angular/core';
import {CustomerAccountDataService} from "../../services/customer-account-data.service";
import {CustomerDataService} from "../../services/customer-data.service";
import {CustomerTransactionDataService} from "../../services/customer-transaction-data.service";
import {CustomerTransactionQueryResult} from "../../models/api/query-result/customer-transaction-query-result";
import {CustomerQueryResult} from "../../models/api/query-result/customer-query-result";
import {CustomerAccountQueryResult} from "../../models/api/query-result/customer-account-query-result";
import {Observable, forkJoin} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-past-transactions',
  templateUrl: './past-transactions.component.html',
  styleUrls: ['./past-transactions.component.scss']
})
export class PastTransactionsComponent implements OnInit
{
  constructor(private customerTransactionDataService: CustomerTransactionDataService,
              private customerDataService: CustomerDataService,
              private customerAccountDataService: CustomerAccountDataService,
              private activatedRoute: ActivatedRoute,
              private titleService: Title)
  { }

  public Customer: CustomerQueryResult;
  public CustomerAccount: CustomerAccountQueryResult;
  public CustomerTransactions: CustomerTransactionQueryResult[];
  public DisplayedCustomerTransactions: CustomerTransactionQueryResult[];
  public LoadingApiData: boolean;
  public PastTransactionDatepickers = PastTransactionDatepickers;
  public ServerConnectionError: boolean;
  public ShowTransactionEndDatepicker: boolean;
  public ShowTransactionStartDatepicker: boolean;
  public TransactionEndDate: Date;
  public TransactionStartDate: Date;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Transaction History');
    this.LoadingApiData = true;
    this.InitDatepickers();

    this.activatedRoute.params.subscribe(paramsP => {
      const premise_id = paramsP['premiseId'];

      const api_data_requests = forkJoin(
        this.customerDataService.GetById(),
        this.customerAccountDataService.GetCustomerAccount(premise_id),
        this.customerTransactionDataService.GetAllForCustomerAccount(premise_id)
      );

      api_data_requests.subscribe(resultsP => {
        this.LoadingApiData = false;

        this.Customer = resultsP[0].Customer;
        this.CustomerAccount = resultsP[1].CustomerAccount;
        this.CustomerTransactions = resultsP[2].CustomerTransactions;

        this.UpdateDisplayedTransactions();
      }, () => {
        this.LoadingApiData = false;
        this.ServerConnectionError = true;
      });
    });
  }

  public PrintPage(): void
  {
    window.print();
  }

  public HideDatepickerPopup(datepickerTypeP: PastTransactionDatepickers): void
  {
    switch (datepickerTypeP)
    {
      case PastTransactionDatepickers.StartDate:
      {
        this.ShowTransactionStartDatepicker = false;
        break;
      }
      case PastTransactionDatepickers.EndDate:
      {
        this.ShowTransactionEndDatepicker = false;
        break;
      }
    }
  }

  public ShowDatepickerPopup(datepickerTypeP: PastTransactionDatepickers): void
  {
    switch (datepickerTypeP)
    {
      case PastTransactionDatepickers.StartDate:
      {
        this.ShowTransactionStartDatepicker = true;
        break;
      }
      case PastTransactionDatepickers.EndDate:
      {
        this.ShowTransactionEndDatepicker = true;
        break;
      }
    }
  }

  public DatepickerValueChanged(datepickerTypeP: PastTransactionDatepickers): void
  {
    this.HideDatepickerPopup(datepickerTypeP);
    this.UpdateDisplayedTransactions();
  }

  private InitDatepickers(): void
  {
    this.ShowTransactionStartDatepicker = false;
    this.ShowTransactionEndDatepicker = false;
    const current_date = new Date();
    this.TransactionStartDate = new Date(current_date.getFullYear() - 5, current_date.getMonth(), 1);
    this.TransactionEndDate = new Date(current_date.getFullYear(), current_date.getMonth() + 1, 0);
  }

  private UpdateDisplayedTransactions(): void
  {
    setTimeout(() => {
      this.DisplayedCustomerTransactions = this.CustomerTransactions.filter(customerTransactionP => {
        if (!customerTransactionP || !customerTransactionP.PayDate)
        {
          return false;
        }

        const pay_date = new Date(customerTransactionP.PayDate.replace(/\s+/g, " ").trim());
        const start_date = new Date(this.TransactionStartDate.getFullYear(), this.TransactionStartDate.getMonth(), 1);
        const end_date = new Date(this.TransactionEndDate.getFullYear(), this.TransactionEndDate.getMonth() + 1, 0);

        return pay_date >= start_date && pay_date <= end_date;
      });
    });
  }
}

enum PastTransactionDatepickers
{
  StartDate,
  EndDate
}
