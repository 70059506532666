import { Component, OnInit } from '@angular/core';
import {AccountDataService} from "../../services/account-data.service";
import {Router} from "@angular/router";
import {UpdateAccountService} from "../../services/update-account.service";
import {PhoneTypeDataService} from "../../services/phone-type-data.service";
import {PhoneTypeQueryResult} from "../../models/api/query-result/phone-type-query-result";
import { Observable, forkJoin } from "rxjs";
import { CustomerDataService } from "../../services/customer-data.service";
import { CustomerQueryResult } from "../../models/api/query-result/customer-query-result";
import { NgForm } from "@angular/forms";
import { ChangePhones } from "../../models/api/command/change-phones";
import { ChangePhone } from "../../models/api/command/change-phone";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-change-phones',
  templateUrl: './change-phones.component.html',
  styleUrls: ['./change-phones.component.scss']
})
export class ChangePhonesComponent implements OnInit
{
  constructor(private accountDataService: AccountDataService,
              private customerDataService: CustomerDataService,
              private updateAccountService: UpdateAccountService,
              private router: Router,
              private phoneNumberTypeDataService: PhoneTypeDataService,
              private titleService: Title)
  { }

  public Customer: CustomerQueryResult;
  public ErrorMessages: string[];
  public LoadingApiData: boolean;
  public PhoneTypes: PhoneTypeQueryResult[];
  public ServerConnectionError: boolean;
  public SubmittingPhoneChanges: boolean;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Update Account - Phones');
    this.LoadingApiData = true;
    this.ErrorMessages = [];
    this.SubmittingPhoneChanges = false;

    const api_data_requests = forkJoin(
      this.customerDataService.GetById(),
      this.phoneNumberTypeDataService.GetAll()
    );

    api_data_requests.subscribe(resultsP => {
      this.LoadingApiData = false;

      this.Customer = resultsP[0].Customer;
      this.PhoneTypes = resultsP[1].PhoneTypes;
    }, () => {
      this.LoadingApiData = false;
      this.ServerConnectionError = true;
    })
  }

  public ChangePhones(formP: NgForm): void
  {
    this.ServerConnectionError = false;
    this.ErrorMessages = [];
    const submission_model = this.GenerateSubmissionModel();

    if (!this.FormIsValid(submission_model))
    {
      return;
    }

    this.SubmittingPhoneChanges = true;
    this.accountDataService.ChangePhones(submission_model).subscribe(resultP => {
      this.SubmittingPhoneChanges = false;

      if (resultP.IsSuccess)
      {
        this.updateAccountService.AccountUpdatedMessage = "Your phone numbers have been updated";
        this.router.navigate(['/account']);
        return;
      }

      this.ErrorMessages= resultP.ErrorMessages;
    }, () => {
      this.SubmittingPhoneChanges = false;
      this.ServerConnectionError = true;
    });
  }

  private ChangePhoneIsValid(changePhoneP: ChangePhone): boolean
  {
    if (!changePhoneP.AreaCode || !changePhoneP.Exchange || !changePhoneP.Line)
    {
      // empty is ok...
      return !changePhoneP.AreaCode && !changePhoneP.Exchange && !changePhoneP.Line;
    }

    if (changePhoneP.AreaCode.length != 3 || changePhoneP.Exchange.length != 3 || changePhoneP.Line.length != 4 || changePhoneP.PhoneTypeID === null)
    {
      return false;
    }

    return true;
  }

  private GenerateSubmissionModel(): ChangePhones
  {
    return {
      Phone: {
        AreaCode: this.Customer.PhoneAreaCode,
        Exchange: this.Customer.PhoneExchange,
        Line: this.Customer.PhoneLine,
        PhoneTypeID: 0
      },
      Phone2: {
        AreaCode: this.Customer.PhoneAreaCode2,
        Exchange: this.Customer.PhoneExchange2,
        Line: this.Customer.PhoneLine2,
        PhoneTypeID: +this.Customer.PhoneNumber2Type
      },
      Phone3: {
        AreaCode: this.Customer.PhoneAreaCode3,
        Exchange: this.Customer.PhoneExchange3,
        Line: this.Customer.PhoneLine3,
        PhoneTypeID: +this.Customer.PhoneNumber3Type
      }
    }
  }

  private FormIsValid(changePhonesP: ChangePhones): boolean
  {
    if (!this.ChangePhoneIsValid(changePhonesP.Phone))
    {
      this.ErrorMessages.push("Phone 1 is invalid");
    }

    if (!this.ChangePhoneIsValid(changePhonesP.Phone2))
    {
      this.ErrorMessages.push("Phone 2 is invalid");
    }

    if (!this.ChangePhoneIsValid(changePhonesP.Phone3))
    {
      this.ErrorMessages.push("Phone 3 is invalid");
    }

    return this.ErrorMessages.length === 0;
  }
}
