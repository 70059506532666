export enum ServiceOrderStatusCodes
{
  InquiryOnly = 0,
  OnHold = 1,
  ReadyForSurveying = 2,
  AssignedToSurvey = 3,
  SurveyCompleted = 4,
  ReadyForLineWork = 5,
  AssignedToCrew = 6,
  LineWorkCompleted = 7,
  ReadyForSomsBilling = 8,
  Billed = 9,
  IssuesSomsNoBillNeeded = 10,
  ClosedAllWorkCompleted = 11,
  ClosedJobCancelled = 12,
  ClosedOutdatedRequest = 13,
  ClosedSiteVisitNoWork = 14
}