import {Injectable} from "@angular/core";
import {BaseApiDataService} from "./base-api-data.service";
import {JwtService} from "./jwt.service";
import {Observable} from "rxjs";
import {PremiseModels} from "../models/api/premise-models";

@Injectable()
export class PremiseDataService
{
  constructor(private baseApiDataService: BaseApiDataService,
              private jwtService: JwtService)
  { }

  public GetForCustomer(): Observable<PremiseModels.GetForCustomer>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiGetRequest(`premises/forcustomer/${customer_id}`)
  }
}
