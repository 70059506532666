import { Component, OnInit } from '@angular/core';
import { CustomerDataService } from "../../services/customer-data.service";
import { CustomerQueryResult } from "../../models/api/query-result/customer-query-result";
import { BillDeliveryTypes } from "../../models/api/enum/bill-delivery-types";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { AccountDataService } from "../../services/account-data.service";
import { UpdateAccountService } from "../../services/update-account.service";
import { ChangeBillDeliveryType } from "../../models/api/command/change-bill-delivery-type";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-change-bill-delivery-type',
  templateUrl: './change-bill-delivery-type.component.html',
  styleUrls: ['./change-bill-delivery-type.component.scss']
})
export class ChangeBillDeliveryTypeComponent implements OnInit
{
  constructor(private customerDataService: CustomerDataService,
              private router: Router,
              private accountDataService: AccountDataService,
              private updateAccountService: UpdateAccountService,
              private titleService: Title)
  { }

  public BillDeliveryTypes = BillDeliveryTypes;
  public Customer: CustomerQueryResult;
  public LoadingApiData: boolean;
  public FormBillDeliveryType: BillDeliveryTypes;
  public ServerConnectionError: boolean;
  public SubmittingChanges: boolean;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Update Account - Bill Delivery Type');
    this.LoadingApiData = true;
    this.SubmittingChanges = false;

    this.customerDataService.GetById().subscribe(resultP => {
      this.LoadingApiData = false;

      if (resultP.IsSuccess)
      {
        this.Customer = resultP.Customer;
        this.FormBillDeliveryType = resultP.Customer.BillDeliveryType;
      }
    }, () => {
      this.LoadingApiData = false;
      this.ServerConnectionError = true;
    })
  }

  public SubmitChanges(formP: NgForm): void
  {
    this.SubmittingChanges = true;
    this.ServerConnectionError = false;

    const submission_model: ChangeBillDeliveryType = {
      CurrentBillDeliveryType: this.Customer.BillDeliveryType,
      NewBillDeliveryType: this.FormBillDeliveryType
    };

    this.accountDataService.ChangeBillDeliveryType(submission_model).subscribe(resultP => {
      this.SubmittingChanges = false;

      if (resultP.IsSuccess)
      {
        this.updateAccountService.AccountUpdatedMessage = "Bill delivery type has been updated";
        this.router.navigate(['/account']);
      }
    }, () => {
      this.SubmittingChanges = false;
      this.ServerConnectionError = true;
    });
  }
}
