import {JwtService} from "./jwt.service";
import {BaseApiDataService} from "./base-api-data.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {DisconnectNoticeModels} from "../models/api/disconnect-notice-models";
import {MonthScopedRequest} from "../models/api/query/month-scoped-request";

@Injectable()
export class DisconnectNoticeDataService
{
  constructor(private baseApiDataService: BaseApiDataService,
              private jwtService: JwtService)
  { }

  public GetAllForCustomerAccount(premiseIdP: string): Observable<DisconnectNoticeModels.GetAllForCustomerAccount>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiGetRequest(`disconnect-notices/customer/${customer_id}/premise/${premiseIdP}/all`);
  }

  public GetPdfFileTokenForPeriod(premiseIdP: string, periodP: MonthScopedRequest): Observable<DisconnectNoticeModels.GetFileRequestTokenForMonth>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiPostRequest(`disconnect-notice/customer/${customer_id}/premise/${premiseIdP}/for-period/generatetoken`, periodP);
  }
}
