﻿import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {UserAuthenticationService} from "../../services/user-authentication.service";
import {JwtService} from "../../services/jwt.service";
import {Title} from "@angular/platform-browser";
import { UpdateAccountService } from 'app/services/update-account.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'mp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{
  constructor(private userAuthenticationService: UserAuthenticationService,
              private router: Router,
              private jwtService: JwtService,
              private titleService: Title,
              private updateAccountService: UpdateAccountService)
  { }

  public LoginErrorMessage: string;
  public AccountUpdatedMessage: string;
  public ServerConnectionError: boolean;
  public WaitingOnServerResponse: boolean;
  public ReCaptchaSiteKey = environment.ReCaptchaButtonSiteKey;
  public ReCaptchaToken: string;

  public ngOnInit(): void
  {
    this.AccountUpdatedMessage = this.updateAccountService.AccountUpdatedMessage;
    this.updateAccountService.ResetAccountUpdatedMessage();
    this.titleService.setTitle('Log In');
    this.WaitingOnServerResponse = false;
    this.LoginErrorMessage = "";
  }

  public LogIn(formP: NgForm): void
  {
    this.LoginErrorMessage = "";
    this.ServerConnectionError = false;

    if (formP.invalid)
    {
      return;
    }

    const user_authentication = formP.value;
    this.WaitingOnServerResponse = true;

    this.userAuthenticationService.LogIn(user_authentication, this.ReCaptchaToken).subscribe((responseP) => {
      this.WaitingOnServerResponse = false;

      if (this.jwtService.CurrentAuthTokenPayload.IsAMultiCustomerAccount)
      {
        this.router.navigate(['select-account']);
      }
      else
      {
        this.router.navigate(['']);
      }
    }, (errorP) => {
      this.WaitingOnServerResponse = false;
      this.ReCaptchaToken = '';
      console.log(errorP);

      if (errorP.status === 401)
      {
        this.LoginErrorMessage = errorP.error.Result.ErrorMessage;
        return;
      }

      this.ServerConnectionError = true;
    })
  }
}
