import { Injectable } from '@angular/core';
import {Observable, throwError} from "rxjs";
import {Router} from "@angular/router";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BaseApiDataService
{
  constructor(private httpClient: HttpClient,
              private router: Router)
  { }

  private m_ApiPrefix: string = "v1";

  public MakeAuthApiGetRequest<T>(relativeUrlP: string): Observable<T>
  {
    return this.httpClient.get<T>(`/${this.m_ApiPrefix}/${relativeUrlP}`)
      .pipe(
        catchError((error, ob) => {
          return this.HandleApiError(error, this.router);
        })
      );
  }

  public MakeAuthApiPostRequest<T>(relativeUrlP: string, bodyP: any, headers?: { [header: string]: string}): Observable<T>
  {
    const options = {
      headers: headers
    }
    return this.httpClient.post<T>(`/${this.m_ApiPrefix}/${relativeUrlP}`, bodyP, options)
      .pipe(
        catchError((error, ob) => {
          return this.HandleApiError(error, this.router);
        })
      );
  }

  private HandleApiError(errorP: HttpErrorResponse, router: Router)
  {
    // Unauthenticated server response should redirect users to the login page. This will happen if a token expires or is deleted.
    if (errorP.status === 401)
    {
      router.navigate(['/unauthenticated/login']);
    }
    // Unauthorized server response should redirect users to an unauthorized page.
    else if (errorP.status === 403)
    {
      router.navigate(['/unauthorized'])
    }

    return throwError(errorP);
  }
}
