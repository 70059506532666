import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {OutageModels} from "../models/api/outage-models";
import {BaseApiDataService} from "./base-api-data.service";
import {JwtService} from "./jwt.service";

@Injectable()
export class PlannedOutageDataService
{
  constructor(private baseDateService: BaseApiDataService,
              private jwtService: JwtService)
  { }

  public GetUpcomingForCustomer(): Observable<OutageModels.GetUpcomingForCustomer>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseDateService.MakeAuthApiGetRequest(`plannedoutages/upcoming/forcustomer/${customer_id}`);
  }
}
