import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-successful-registration',
  templateUrl: './successful-registration.component.html',
  styleUrls: ['./successful-registration.component.scss']
})
export class SuccessfulRegistrationComponent implements OnInit
{
  constructor(private titleService: Title)
  { }

  public ngOnInit(): void
  {
    this.titleService.setTitle('Registered Successfully');
  }
}
