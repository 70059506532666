import { Component, OnInit } from '@angular/core';
import {AccountDataService} from "../../services/account-data.service";
import {NgForm} from "@angular/forms";
import {UpdateAccountService} from "../../services/update-account.service";
import {Router} from "@angular/router";
import {ChangeEmail} from "../../models/api/command/change-email";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit
{
  constructor(private accountDataService: AccountDataService,
              private updateAccountService: UpdateAccountService,
              private router: Router,
              private titleService: Title)
  { }

  public ErrorMessage: string;
  public ServerConnectionError: boolean;
  public SubmittingChange: boolean;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Update Account - Email');
    this.SubmittingChange = false;
  }

  public SubmitEmailChange(formP: NgForm): void
  {
    this.ErrorMessage = "";
    this.ServerConnectionError = false;

    if (formP.invalid)
    {
      return;
    }

    const change_email_model: ChangeEmail = formP.value;
    this.SubmittingChange = true;

    this.accountDataService.ChangeEmail(change_email_model).subscribe(resultP => {
      this.SubmittingChange = false;

      if (resultP.IsSuccess)
      {
        this.updateAccountService.AccountUpdatedMessage = "Your email has been changed";
        this.router.navigate(['/account']);
        return;
      }

      if (resultP.IsFailure && resultP.Result.EmailAlreadyInUse)
      {
        this.ErrorMessage = "Unable to change your email address.  This address may be used on another account.";
        return;
      }
    }, () => {
      this.SubmittingChange = false;
      this.ServerConnectionError = true;
    })
  }
}
