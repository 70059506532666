﻿import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './components/app/app.component';
import {LoginComponent} from './components/login/login.component';
import {RoutingModule} from "./modules/routing/routing.module";
import {BaseApiDataService} from "./services/base-api-data.service";
import {PlannedOutageDataService} from "./services/planned-outage-data.service";
import {JwtService} from "./services/jwt.service";
import { AuthenticatedComponent } from './components/authenticated/authenticated.component';
import {LaddaModule} from "angular2-ladda";
import { RegistrationComponent } from './components/registration/registration.component';
import { UnAuthenticatedComponent } from './components/un-authenticated/un-authenticated.component';
import { EqualsValidatorDirective } from './directives/equals-validator/equals-validator.directive';
import { SuccessfulRegistrationComponent } from './components/successful-registration/successful-registration.component';
import {CustomerDataService} from "./services/customer-data.service";
import { SelectAccountComponent } from './components/select-account/select-account.component';
import {MEBalanceFormatPipe} from "./pipes/me-balance-format.pipe";
import {UserAuthenticationService} from "./services/user-authentication.service";
import { HomeComponent } from './components/home/home.component';
import {MomentModule} from "ngx-moment";
import { LoadingPanelComponent } from './components/loading-panel/loading-panel.component';
import { PlannedOutagesComponent } from './components/planned-outages/planned-outages.component';
import {BsDropdownModule, CollapseModule, DatepickerModule, ModalModule} from "ngx-bootstrap";
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ReportAnOutageComponent } from './components/report-an-outage/report-an-outage.component';
import {PremiseDataService} from "./services/premise-data.service";
import {OutageClueDataService} from "./services/outage-clue-data.service";
import {OutageDataService} from "./services/outage-data.service";
import { OutageReportedComponent } from './components/outage-reported/outage-reported.component';
import {BillDataService} from "./services/bill-data.service";
import {PdfService} from "./services/pdf.service";
import { PastBillsComponent } from './components/past-bills/past-bills.component';
import {CustomerAccountDataService} from "./services/customer-account-data.service";
import { PastTransactionsComponent } from './components/past-transactions/past-transactions.component';
import {CustomerTransactionDataService} from "./services/customer-transaction-data.service";
import {DisconnectNoticeDataService} from "./services/disconnect-notice-data.service";
import { DisconnectNoticesComponent } from './components/disconnect-notices/disconnect-notices.component';
import { AccountComponent } from './components/account/account.component';
import {PhoneTypeDataService} from "./services/phone-type-data.service";
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import {UpdateAccountService} from "./services/update-account.service";
import {AccountDataService} from "./services/account-data.service";
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ChangePhonesComponent } from './components/change-phones/change-phones.component';
import { ChangeBillDeliveryTypeComponent } from './components/change-bill-delivery-type/change-bill-delivery-type.component';
import { SendRecoveryEmailComponent } from './components/send-recovery-email/send-recovery-email.component';
import { RecoverDataService } from "./services/recover-data.service";
import { ChooseNewPasswordForRecoveryComponent } from './components/choose-new-password-for-recovery/choose-new-password-for-recovery.component';
import { PreAuthorizedAccountComponent } from './components/pre-authorized-account/pre-authorized-account.component';
import {PreAuthorizedAccountDataService} from "./services/pre-authorized-account-data.service";
import { ManagePreAuthorizedAccountsComponent } from './components/manage-pre-authorized-accounts/manage-pre-authorized-accounts.component';
import { PremiseSelectionModalComponent } from './components/premise-selection-modal/premise-selection-modal.component';
import { ConfirmPremiseRemovalModalComponent } from './components/confirm-premise-removal-modal/confirm-premise-removal-modal.component';
import {MeSecureNumberDisplayPipe} from "./pipes/me-secure-number-display.pipe";
import {PreAuthorizedAccountService} from "./services/pre-authorized-account.service";
import { RemovePreAuthorizedAccountModalComponent } from './components/remove-pre-authorized-account-modal/remove-pre-authorized-account-modal.component';
import { ListedAlertComponent } from './components/listed-alert/listed-alert.component';
import { ServerErrorAlertComponent } from './components/server-error-alert/server-error-alert.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { UnAuthenticatedFooterComponent } from './components/un-authenticated-footer/un-authenticated-footer.component';
import { UnAuthenticatedHeaderComponent } from './components/un-authenticated-header/un-authenticated-header.component';
import { PreAuthorizedAccountConfirmationComponent } from './components/pre-authorized-account-confirmation/pre-authorized-account-confirmation.component';
import {MeInstitutionNamePipe} from "app/pipes/me-institution-name.pipe";
import {AddingLeadingZeroesToNumberPipe} from "./pipes/me-add-leading-zeros-to-number.pipe";
import { DisconnectRequestComponent } from './components/disconnect-request/disconnect-request.component';
import { FormsService } from "./services/forms.service";
import { ServiceRequestSuccessComponent } from './components/service-request-success/service-request-success.component';
import { BookPresentationComponent } from './components/book-presentation/book-presentation.component';
import { CommentCardComponent } from './components/comment-card/comment-card.component';
import { StaycationContestComponent } from './components/staycation-contest/staycation-contest.component';
import { RequestDonationComponent } from './components/request-donation/request-donation.component';
import { StreetlightRepairComponent } from './components/streetlight-repair/streetlight-repair.component';
import { TreeTrimmingComponent } from './components/tree-trimming/tree-trimming.component';
import { ReportAnOutageUnauthenticatedComponent } from './components/report-an-outage-unauthenticated/report-an-outage-unauthenticated.component';
import { PublicLoginUnauthenticatedComponent } from './components/public-login-unauthenticated/public-login-unauthenticated.component';
import { NewServiceRequestComponent } from './components/new-service-request/new-service-request.component';
import { ServiceRequestErrorComponent } from './components/service-request-error/service-request-error.component';
import { ArrearsComponent } from './components/arrears/arrears.component';
import { MyDatePickerModule } from './components/angular2-datepicker';
import { environment } from 'environments/environment';
import { JwtModule } from '@auth0/angular-jwt';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';;
import { ServiceRequestContactMeclComponent } from './components/service-request-contact-mecl/service-request-contact-mecl.component'
;
import { DeferredPaymentsComponent } from './components/deferred-payments/deferred-payments.component'
import { ServiceRequestsComponent } from './components/service-requests/service-requests.component';

export function jwtTokenGetter() {
  return localStorage.getItem(environment.JWTKey);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AuthenticatedComponent,
    RegistrationComponent,
    UnAuthenticatedComponent,
    EqualsValidatorDirective,
    SuccessfulRegistrationComponent,
    SelectAccountComponent,
    MEBalanceFormatPipe,
    HomeComponent,
    ServiceRequestsComponent,
    LoadingPanelComponent,
    PlannedOutagesComponent,
    LoadingSpinnerComponent,
    ReportAnOutageComponent,
    OutageReportedComponent,
    PastBillsComponent,
    PastTransactionsComponent,
    DisconnectNoticesComponent,
    AccountComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    ChangePhonesComponent,
    ChangeBillDeliveryTypeComponent,
    SendRecoveryEmailComponent,
    ChooseNewPasswordForRecoveryComponent,
    PreAuthorizedAccountComponent,
    ManagePreAuthorizedAccountsComponent,
    PremiseSelectionModalComponent,
    ConfirmPremiseRemovalModalComponent,
    MeSecureNumberDisplayPipe,
    RemovePreAuthorizedAccountModalComponent,
    ListedAlertComponent,
    ServerErrorAlertComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    UnAuthenticatedFooterComponent,
    UnAuthenticatedHeaderComponent,
    PreAuthorizedAccountConfirmationComponent,
    MeInstitutionNamePipe,
    AddingLeadingZeroesToNumberPipe,
    DisconnectRequestComponent,
    ServiceRequestSuccessComponent,
    BookPresentationComponent,
    CommentCardComponent,
    StaycationContestComponent,
    ArrearsComponent,
    RequestDonationComponent,
    StreetlightRepairComponent,
    TreeTrimmingComponent,
    ReportAnOutageUnauthenticatedComponent,
    PublicLoginUnauthenticatedComponent,
    NewServiceRequestComponent,
    ServiceRequestErrorComponent
,
    ServiceRequestContactMeclComponent
,
    DeferredPaymentsComponent  ],
  imports: [
    MyDatePickerModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    }),
    LaddaModule.forRoot({
      style: "expand-right"
    }),
    MomentModule,
    BsDropdownModule.forRoot(),
    DatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    BaseApiDataService,
    PlannedOutageDataService,
    UserAuthenticationService,
    JwtService,
    CustomerDataService,
    PremiseDataService,
    OutageDataService,
    OutageClueDataService,
    BillDataService,
    PdfService,
    CustomerAccountDataService,
    CustomerTransactionDataService,
    DisconnectNoticeDataService,
    PhoneTypeDataService,
    UpdateAccountService,
    AccountDataService,
    RecoverDataService,
    PreAuthorizedAccountDataService,
    PreAuthorizedAccountService,
    FormsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule
{ }
