﻿import { Component, OnInit } from '@angular/core';
import { OutageClueQueryResult } from "../../models/api/query-result/outage-clue-query-result";
import { PremiseDataService } from "../../services/premise-data.service";
import { OutageClueDataService } from "../../services/outage-clue-data.service";
import { OutageDataService } from "../../services/outage-data.service";
import { PremiseQueryResult } from "../../models/api/query-result/premise-query-result";
import { Observable, forkJoin } from "rxjs";
import { JwtService } from "../../services/jwt.service";
import { NgForm } from "@angular/forms";
import { ReportOutage } from "../../models/api/command/report-outage";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'mp-report-an-outage-unauthenticated',
  templateUrl: './report-an-outage-unauthenticated.component.html',
  styleUrls: ['./report-an-outage-unauthenticated.component.scss']
})
export class ReportAnOutageUnauthenticatedComponent implements OnInit {
  constructor(private premiseDataService: PremiseDataService,
    private outageClueDataService: OutageClueDataService,
    private outageDataService: OutageDataService,
    private router: Router,
    private titleService: Title)
  { }

  public FetchingApiData: boolean;
  public OutageClues: OutageClueQueryResult[];
  public Premises: PremiseQueryResult[];
  public ReportOutageErrorMessages: string[];
  public ServerConnectionError: boolean;
  public SubmittingReportOutage: boolean;

  public ngOnInit(): void {
    this.titleService.setTitle('Report an Outage');
    this.SubmittingReportOutage = false;
    this.FetchingApiData = true;

    // Join our two api requests together so they can be executed in parallel while awaited for at once.
    // https://github.com/Reactive-Extensions/RxJS/blob/master/doc/api/core/operators/forkjoin.md
    const api_calls = forkJoin(this.outageClueDataService.GetAllOutageClues());

    api_calls.subscribe((resultsP) => {
      this.FetchingApiData = false;

      this.OutageClues = resultsP[0].OutageClues;
    }, (errorP) => {
      this.FetchingApiData = false;
      this.ServerConnectionError = true;
    })
  }

  public ReportOutage(reportOutageFormP: NgForm): void {
    this.ResetServerErrorMessaging();
    
    if (reportOutageFormP.invalid) {
      return;
    }

    this.SubmittingReportOutage = true;

    this.outageDataService.ReportAnOutageUnauthenticated(reportOutageFormP.value).subscribe((resultP) => {
      this.SubmittingReportOutage = false;

      if (resultP.Result.CustomerAccountProblem){
        this.router.navigate(["/forms/contact-mecl"]);
        return;
      }

      // Navigate to the success page where the appropriate messaging will be displayed
      if (resultP.IsSuccess) {
        this.router.navigate(["/forms/success"]);
        return;
      }

      // Add any server driven error messaging for display
      if (resultP.IsFailure) {
        this.router.navigate(["/forms/error"]);
        return;
      }
    }, (errorP) => {
      this.SubmittingReportOutage = false;
      this.ServerConnectionError = true;
      this.router.navigate(["/forms/error"]);
      return;
    });

    return;
  }

  private ResetServerErrorMessaging(): void {
    this.ServerConnectionError = false;
    this.ReportOutageErrorMessages = [];
  }
}
