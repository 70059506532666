import {Injectable} from "@angular/core";

@Injectable()
export class PdfService
{
  public DownloadPdf(pdfLinkP: string): void
  {
    const file_path = pdfLinkP;
    const a = document.createElement('a');

    a.href = file_path;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
