import { Component, OnInit } from '@angular/core';
import {CustomerPlannedOutageQueryResult} from "../../models/api/query-result/customer-planned-outage-query-result";
import {PlannedOutageDataService} from "../../services/planned-outage-data.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-planned-outages',
  templateUrl: './planned-outages.component.html',
  styleUrls: ['./planned-outages.component.scss']
})
export class PlannedOutagesComponent implements OnInit
{
  constructor(private plannedOutageDataService: PlannedOutageDataService,
              private titleService: Title)
  { }

  public LoadingApiData: boolean;
  public PlannedOutages: CustomerPlannedOutageQueryResult[];
  public ServerConnectionError: boolean;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Planned Outages');
    this.LoadingApiData = true;

    this.plannedOutageDataService.GetUpcomingForCustomer().subscribe((resultP) => {
      this.LoadingApiData = false;

      if(resultP.IsSuccess)
      {
        this.PlannedOutages = resultP.PlannedOutages;
      }
    }, () => {
      this.LoadingApiData = false;
      this.ServerConnectionError = true;
    })
  }
}
