﻿import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { FormsService } from "../../services/forms.service";
import { FormModels } from "../../models/api/form-models";
import { Router } from "@angular/router";

@Component({
  selector: 'mp-book-presentation',
  templateUrl: './book-presentation.component.html',
  styleUrls: ['./book-presentation.component.scss']
})
export class BookPresentationComponent implements OnInit {

  constructor(private formsService: FormsService,
    private router: Router) { }

  public BookPresentationErrorMessages: string[];
  public ServerConnectionError: boolean;
  public ActiveServerRequest: boolean;


  ngOnInit() {
    this.ResetServerFormErrors();
  }

  public SendToApi(formP: NgForm): void {

    //If form is invalid, we exit.
    if (formP.invalid) {
      return;
    }

    //Send Form Results to FormsService
    this.formsService.BookPresentation(formP.value).subscribe((bookPresentationResultP: FormModels.BookPresentation) => {
      this.ActiveServerRequest = false;

      if (bookPresentationResultP.IsSuccess) {
        this.router.navigate(['/forms/success']);
      }

      if (bookPresentationResultP.IsFailure) {
        this.router.navigate(['/forms/error']);
      }

      this.BookPresentationErrorMessages = bookPresentationResultP.ErrorMessages;

    }, (errorP) => {
      this.ActiveServerRequest = false;
      this.ServerConnectionError = true;
      this.router.navigate(['/forms/error']);
      return;
    });
  }

  private ResetServerFormErrors(): void {
    this.BookPresentationErrorMessages = [];
    this.ServerConnectionError = false;
  }
}