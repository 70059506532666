﻿import { Component, OnInit } from '@angular/core';
import {UserAuthenticationService} from "../../services/user-authentication.service";
import { NavigationStart, Router } from "@angular/router";
import { Subscription } from 'rxjs';

@Component({
  selector: 'mp-authenticated-home',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent implements OnInit
{
  constructor(private userAuthenticationService: UserAuthenticationService,
              private router: Router)
  { }

  public NavIsCollapsed: boolean;
  public ResetPassword: boolean;
  private m_NavSubscription: Subscription;

  public ngOnInit(): void
  {
    this.NavIsCollapsed = true;
    this.ResetPassword = this.userAuthenticationService.UserRequiresPasswordUpdate();

    this.m_NavSubscription = this.router.events.subscribe(eventP => {
      if (eventP instanceof NavigationStart && this.NavIsCollapsed === false)
      {
        this.NavIsCollapsed = true;
      }
    })
  }

  public ngOnDestroy()
  {
    if (this.m_NavSubscription)
    {
      this.m_NavSubscription.unsubscribe();
    }
  }

  public LogOut(): void
  {
    this.userAuthenticationService.LogOut();
  }
}
