﻿import { Component, OnInit } from '@angular/core';
import { RecoverDataService } from "../../services/recover-data.service";
import { NgForm } from "@angular/forms";
import { SendRecoveryEmail } from "../../models/api/command/send-recovery-email";
import {Title} from "@angular/platform-browser";
import { environment } from 'environments/environment';

@Component({
  selector: 'mp-send-recovery-email',
  templateUrl: './send-recovery-email.component.html',
  styleUrls: ['./send-recovery-email.component.scss']
})
export class SendRecoveryEmailComponent implements OnInit
{
  constructor(private recoveryDataService: RecoverDataService,
              private titleService: Title)
  { }

  public ErrorMessage: string;
  public SubmittingToApi: boolean;
  public SuccessMessage: string;
  public ReCaptchaSiteKey = environment.ReCaptchaButtonSiteKey;
  public ReCaptchaToken: string;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Send Recovery Email');
    this.SubmittingToApi = false;
  }

  public SendRecoveryEmail(formP: NgForm): void
  {
    this.ErrorMessage = "";
    this.SuccessMessage = "";

    if (formP.invalid)
    {
      return;
    }

    this.SubmittingToApi = true;
    const submission_model: SendRecoveryEmail = {
      Email: formP.value.Email
    };

    this.recoveryDataService.SendRecoveryEmail(submission_model, this.ReCaptchaToken).subscribe(resultP => {
      this.SubmittingToApi = false;

      if (resultP.IsSuccess)
      {
        this.titleService.setTitle('Recovery Email Sent');
        this.SuccessMessage = "Please check your inbox. If your email was on file, then a recovery email has been sent that contains reset instructions.";
      }
    }, () => {
      this.ReCaptchaToken = "";
      this.SubmittingToApi = false;
    })
  }
}
