
import {mergeMap, tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {JwtService} from "./jwt.service";
import {BaseApiDataService} from "./base-api-data.service";
import {UserAuthentication} from "../models/api/command/user-authentication";
import {Observable, of} from "rxjs";
import {UserAuthenticationModels} from "../models/api/user-authentication-models";
import {Router} from "@angular/router";

@Injectable()
export class UserAuthenticationService
{
  constructor(private router: Router,
              private jwtService: JwtService,
              private baseApiDataService: BaseApiDataService)
  { }

  public LogIn(loginModelP: UserAuthentication, reCaptchaToken: string): Observable<UserAuthenticationModels.LogIn>
  {
    return this.baseApiDataService.MakeAuthApiPostRequest<UserAuthenticationModels.LogIn>(`authentication/login`, loginModelP, { "RECAPTCHA_TOKEN": reCaptchaToken })
      .pipe(
        tap((resultP: UserAuthenticationModels.LogIn) => {
          const token = resultP.Result.Token

          if (resultP.IsSuccess && token)
          {
            this.jwtService.SetAuthToken(token);
          }
        })
      );
  }

  public LogOut(): void
  {
    this.jwtService.ClearAuthToken();
    this.router.navigate(['/unauthenticated/login']);
  }

  public UserIsLoggedIn(): boolean
  {
    return this.jwtService.AuthTokenIsStillValid();
  }

  public UserRequiresPasswordUpdate(): boolean
  {
    return this.jwtService.UserRequiresPasswordUpdate();
  }
}
