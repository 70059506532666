﻿import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { FormsService } from "../../services/forms.service";
import { FormModels } from "../../models/api/form-models";
import { Router } from "@angular/router";

@Component({
  selector: 'mp-staycation-contest',
  templateUrl: './staycation-contest.component.html',
  styleUrls: ['./staycation-contest.component.scss']
})
export class StaycationContestComponent implements OnInit {

  constructor(private formsService: FormsService,
    private router: Router) { }

  public StaycationContestErrorMessages: string[];
  public ServerConnectionError: boolean;
  public ActiveServerRequest: boolean;

  updateAccepted(input: HTMLInputElement, button: HTMLInputElement): void {
	if (input.checked)
		button.disabled = false;
	else
		button.disabled = true;
  }

  ngOnInit() {
    this.ResetServerFormErrors();
  }

  public SendToApi(formP: NgForm): void {

    //If form is invalid, we exit.
    if (formP.invalid) {
      return;
    }

    //Send Form Results to FormsService
    this.formsService.StaycationContest(formP.value).subscribe((staycationContestResultP: FormModels.StaycationContest) => {
      this.ActiveServerRequest = false;

      if (staycationContestResultP.IsSuccess) {
        this.router.navigate(['/forms/success']);
      }

      if (staycationContestResultP.IsFailure) {
        this.router.navigate(['/forms/error']);
      }

      this.StaycationContestErrorMessages = staycationContestResultP.ErrorMessages;

    }, (errorP) => {
      this.ActiveServerRequest = false;
      this.ServerConnectionError = true;
      this.router.navigate(['/forms/error']);
      return;
    });
  }

  private ResetServerFormErrors(): void {
    this.StaycationContestErrorMessages = [];
    this.ServerConnectionError = false;
  }
}
