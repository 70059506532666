import { Component, OnInit } from '@angular/core';
import {CustomerDataService} from "../../services/customer-data.service";
import {BillDataService} from "../../services/bill-data.service";
import {BillInfoQueryResult} from "../../models/api/query-result/bill-info-query-result";
import {CustomerAccountQueryResult} from "../../models/api/query-result/customer-account-query-result";
import {CustomerQueryResult} from "../../models/api/query-result/customer-query-result";
import {CustomerAccountDataService} from "../../services/customer-account-data.service";
import {Observable, forkJoin} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {PdfService} from "../../services/pdf.service";
import {MonthScopedRequest} from "../../models/api/query/month-scoped-request";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-past-bills',
  templateUrl: './past-bills.component.html',
  styleUrls: ['./past-bills.component.scss']
})
export class PastBillsComponent implements OnInit {

  constructor(private customerDataService: CustomerDataService,
              private billDataService: BillDataService,
              private customerAccountDataService: CustomerAccountDataService,
              private pdfService: PdfService,
              private activatedRoute: ActivatedRoute,
              private titleService: Title)
  { }

  public Customer: CustomerQueryResult;
  public CustomerAccount: CustomerAccountQueryResult;
  public LoadingApiData: boolean;
  public PastBillInfo: BillInfoQueryResult[];
  public ServerConnectionError: boolean;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Past Bills');
    this.LoadingApiData = true;

    this.activatedRoute.params.subscribe(paramsP => {
      const premise_id = paramsP['premiseId'];

      const api_data_requests = forkJoin(
        this.customerDataService.GetById(),
        this.customerAccountDataService.GetCustomerAccount(premise_id),
        this.billDataService.GetPastBillInformation(premise_id));

      api_data_requests.subscribe((resultsP) => {
        this.Customer = resultsP[0].Customer;
        this.CustomerAccount = resultsP[1].CustomerAccount;
        this.PastBillInfo = resultsP[2].PastBillInfo;

        this.LoadingApiData = false;
      }, () => {
        this.LoadingApiData = false;
        this.ServerConnectionError = true;
      })
    })
  }

  public DownloadBill(premiseIdP: string, billingMonthP: string, billingYearP: string): void
  {
    this.ServerConnectionError = false;

    const pdf_by_period_model: MonthScopedRequest = {
      Month: parseInt(billingMonthP),
      Year: parseInt(billingYearP)
    };

    this.billDataService.GetTokenForPeriod(premiseIdP, pdf_by_period_model).subscribe(resultP => {

      if (resultP.IsSuccess)
      {
        this.pdfService.DownloadPdf(resultP.PdfUrl);
      }
    }, () => {
      this.ServerConnectionError = true;
    })
  }
}
