import {Injectable} from "@angular/core";
import {BaseApiDataService} from "./base-api-data.service";
import {Observable} from "rxjs";
import {PhoneTypeModels} from "../models/api/phone-type-models";

@Injectable()
export class PhoneTypeDataService
{
  constructor(private baseApiDataService: BaseApiDataService)
  { }

  public GetAll(): Observable<PhoneTypeModels.GetAll>
  {
    return this.baseApiDataService.MakeAuthApiGetRequest(`phone-types/all`);
  }
}
