import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PreAuthorizedAccountDataService} from "../../services/pre-authorized-account-data.service";
import {CustomerAccountDataService} from "../../services/customer-account-data.service";
import {PreAuthorizedAccountQueryResult} from "../../models/api/query-result/pre-authorized-account-query-result";
import {Observable, forkJoin} from "rxjs";
import {CustomerAccountWithPreAuthQueryResult} from "../../models/api/query-result/customer-account-pre-auth-query-result";
import {PremiseSelectionModalComponent} from "../premise-selection-modal/premise-selection-modal.component";
import {ConfirmPremiseRemovalModalComponent} from "../confirm-premise-removal-modal/confirm-premise-removal-modal.component";
import {PreAuthorizedAccountAdd} from "../../models/api/command/pre-authorized-account-add";
import {JwtService} from "../../services/jwt.service";
import {PreAuthorizedAccountService} from "../../services/pre-authorized-account.service";
import {PreAuthorizedAccountUpdate} from "../../models/api/command/pre-authorized-account-update";
import {Title} from "@angular/platform-browser";
import {PreAuthorizedAccountConfirmationTypes} from "../../models/app/enum/pre-authorized-account-confirmation-types";
import {ValidateBankAccount} from "../../models/app/validator-functions";
import {PrependLeadingZerosToNumber} from "../../pipes/me-add-leading-zeros-to-number.pipe";

@Component({
  selector: 'mp-pre-authorized-payment',
  templateUrl: './pre-authorized-account.component.html',
  styleUrls: ['./pre-authorized-account.component.scss']
})
export class PreAuthorizedAccountComponent implements OnInit
{
  @ViewChild(PremiseSelectionModalComponent)
  public PremiseSelectionModal: PremiseSelectionModalComponent;

  @ViewChild(ConfirmPremiseRemovalModalComponent)
  public ConfirmPremiseRemovalModal: ConfirmPremiseRemovalModalComponent;

  constructor(private activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder,
              private preAuthorizedAccountService: PreAuthorizedAccountService,
              private preAuthorizedAccountDataService: PreAuthorizedAccountDataService,
              private router: Router,
              private customerAccountDataService: CustomerAccountDataService,
              private jwtService: JwtService,
              private titleService: Title)
  { }

  public LoadingApiData: boolean;
  public SubmittingFormValuesToApi: boolean;

  public AlertErrorMessages: string[];
  public ClientFormErrors: any;
  public ClientFormValidationMessages: any;
  public PreAuthForm: FormGroup;
  public PreAuthFormSubmitted: boolean;

  public PreAuthorizedAccount: PreAuthorizedAccountQueryResult;
  public Premises: CustomerAccountWithPreAuthQueryResult[];
  public SelectedPremises: CustomerAccountWithPreAuthQueryResult[];
  public UnselectedPremises: CustomerAccountWithPreAuthQueryResult[];

  public ngOnInit(): void
  {
    this.titleService.setTitle('Pre-Authorized Payment Plan');
    this.SubmittingFormValuesToApi = false;
    this.InitializeForm();

    this.LoadingApiData = true;
    this.activatedRoute.params.subscribe((paramsP) => {

      const premise_id = paramsP['premiseId'];

      // Load premises and account if we have a premise ID
      if (premise_id)
      {
        this.titleService.setTitle('Pre-Authorized Payment Plan - Add');

        const api_data_calls = forkJoin(
          this.customerAccountDataService.GetWithPreAuthForCustomer(),
          this.preAuthorizedAccountDataService.GetByCustomerAndPremiseID(premise_id)
        );

        api_data_calls.subscribe(resultsP => {
          this.LoadingApiData = false;

          this.Premises = resultsP[0].CustomerAccounts;

          if (resultsP[1].IsSuccess)
          {
            this.PreAuthorizedAccount = resultsP[1].PreAuthorizedAccount;
          }
          else
          {
            // Navigate away if we can't find the premise
            this.router.navigate(['/manage-pre-authorized-accounts']);
          }

          this.UpdateFormValuesAndPopulatePremiseLists(this.PreAuthorizedAccount);
        }, () => {
          this.LoadingApiData = false;
        })
      }
      else
      {
        this.titleService.setTitle('Pre-Authorized Payment Plan - Modify');
        this.customerAccountDataService.GetWithPreAuthForCustomer().subscribe((resultsP) => {
          this.LoadingApiData = false;

          this.Premises = resultsP.CustomerAccounts;
          this.UpdateFormValuesAndPopulatePremiseLists();

          // Load values into form/generate what we need
        }, () => {
          this.LoadingApiData = false;
        })
      }
    });
  }

  public LaunchAddPremisesModal(): void
  {
    this.PremiseSelectionModal.ShowModal();
  }

  public LaunchConfirmPremiseRemovalComponent(premiseToRemoveP: CustomerAccountWithPreAuthQueryResult): void
  {
    this.ConfirmPremiseRemovalModal.ShowModal(premiseToRemoveP);
  }

  public AddPremiseToAccount(premiseToAddP: CustomerAccountWithPreAuthQueryResult): void
  {
    this.ResetAlertErrorMessages();

    this.UnselectedPremises = this.UnselectedPremises.filter(premiseP => {
      return premiseP.PremiseID !== premiseToAddP.PremiseID;
    }); 
    
    this.SelectedPremises.push(premiseToAddP);
  }

  public RemovePremiseFromAccount(premiseToRemoveP: CustomerAccountWithPreAuthQueryResult): void
  {
    this.SelectedPremises = this.SelectedPremises.filter(premiseP => {
      return premiseP.PremiseID !== premiseToRemoveP.PremiseID;
    });

    this.UnselectedPremises.push(premiseToRemoveP);
  }

  public SubmitPreAuthorizedAccount(): void
  {
    this.ResetAlertErrorMessages();
    this.PreAuthFormSubmitted = true;
    this.UpdateFormValidationMessaging();

    if (this.PreAuthForm.invalid)
    {
      return;
    }

    if (this.SelectedPremises.length < 1)
    {
      this.AlertErrorMessages.push("At least one account must be selected for this pre-authorized payment plan");
      setTimeout(() => {
        this.ScrollToAlert();
      });
      return;
    }

    // Decide whether we are adding or updating.
    if (this.PreAuthorizedAccount)
    {
      this.UpdateExistingAccount();
    }
    else
    {
      this.AddNewAccount();
    }
  }

  private AddNewAccount(): void
  {
    const submission_model: PreAuthorizedAccountAdd = {
      CustomerID: this.jwtService.CurrentAuthTokenPayload.CustomerID,
      PremiseIDs: this.SelectedPremises.map(x => x.PremiseID),
      BankNumber: this.PreAuthForm.get('bankNumber').value,
      BranchNumber: this.PreAuthForm.get('branchNumber').value,
      AccountNumber: this.PreAuthForm.get('accountNumber').value,
      AuthorizedByCustomer: this.PreAuthForm.get('authorizedByCustomer').value.toString()
    };

    this.SubmittingFormValuesToApi = true;
    this.preAuthorizedAccountDataService.Add(submission_model).subscribe((resultP) => {
      this.SubmittingFormValuesToApi = false;

      if (resultP.IsSuccess)
      {
        this.preAuthorizedAccountService.CachePreAuthorizedAccountConfirmation(PreAuthorizedAccountConfirmationTypes.Add, submission_model, resultP.Result);
        this.router.navigate(['/pre-authorized-account-confirmation']);
        return;
      }

      this.AlertErrorMessages = resultP.ErrorMessages;
      setTimeout(() => {
        this.ScrollToAlert();
      });
    }, () => {
      this.SubmittingFormValuesToApi = false;
      this.AlertErrorMessages.push("There was an error connecting to the server. Please try again, or contact Maritime Electric if the problem persists.");
    });
  }

  private UpdateExistingAccount(): void
  {
    const submission_model: PreAuthorizedAccountUpdate = {
      CustomerID: this.jwtService.CurrentAuthTokenPayload.CustomerID,
      PremiseIDs: this.SelectedPremises.map(x => x.PremiseID),
      BankNumber: this.PreAuthForm.get('bankNumber').value,
      BranchNumber: this.PreAuthForm.get('branchNumber').value,
      AccountNumber: this.PreAuthForm.get('accountNumber').value,
      AuthorizedByCustomer: this.PreAuthForm.get('authorizedByCustomer').value,
      CurrentBankNumber: this.PreAuthorizedAccount.BankNumber,
      CurrentBranchNumber: this.PreAuthorizedAccount.BranchNumber,
      CurrentAccountNumber: this.PreAuthorizedAccount.AccountNumber
    };

    this.SubmittingFormValuesToApi = true;
    this.preAuthorizedAccountDataService.Update(submission_model).subscribe((resultP) => {
      this.SubmittingFormValuesToApi = false;

      if (resultP.IsSuccess)
      {
        this.preAuthorizedAccountService.CachePreAuthorizedAccountConfirmation(PreAuthorizedAccountConfirmationTypes.Update, submission_model, resultP.Result);
        this.router.navigate(['/pre-authorized-account-confirmation']);
        return;
      }

      this.AlertErrorMessages = resultP.ErrorMessages;
      setTimeout(() => {
        this.ScrollToAlert();
      });
    }, () => {
      this.SubmittingFormValuesToApi = false;
      this.AlertErrorMessages.push("There was an error connecting to the server. Please try again, or contact Maritime Electric if the problem persists.");
    });
  }

  private InitializeForm(): void
  {
    this.PreAuthFormSubmitted = false;
    this.PreAuthForm = this.formBuilder.group({
      bankNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{3}$/), (formControlP) => { return ValidateBankAccount(formControlP, this.preAuthorizedAccountService.ValidFinancialInstitutions);} ]],
      branchNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
      accountNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{5,12}$/)]],
      authorizedByCustomer: [false, Validators.requiredTrue]
    });

    this.ClientFormErrors = {
      'bankNumber': '',
      'branchNumber': '',
      'accountNumber': '',
      'authorizedByCustomer': ''
    };

    this.ClientFormValidationMessages = {
      'bankNumber': {
        'required': 'Institution Number is required.',
        'pattern': 'Institution Number must be 3 digits',
        'validFinancialInstitutionNumber': 'The Institution Number has not passed the security check. Please review the information for accuracy.'
      },
      'branchNumber': {
        'required': 'Branch Number is required.',
        'pattern': 'Branch Number must 5 digits'
      },
      'accountNumber': {
        'required': 'Account Number is required.',
        'pattern': 'Account Number must be 5-12 digits'
      },
      'authorizedByCustomer': {
        'required': 'Approval is required.'
      }
    };

    this.PreAuthForm.valueChanges.subscribe(() => {
      this.UpdateFormValidationMessaging();
    });

    this.UpdateFormValidationMessaging();
  }

  private UpdateFormValidationMessaging(): void
  {
    if (!this.PreAuthForm)
    {
      return;
    }

    const form = this.PreAuthForm;

    for (const field in this.ClientFormErrors)
    {
      // clear previous error message (if any)
      this.ClientFormErrors[field] = {};

      const control = form.get(field);

      if (control && !control.valid && (control.dirty || control.touched || this.PreAuthFormSubmitted))
      {
        const messages = this.ClientFormValidationMessages[field];

        for (const key in control.errors)
        {
          this.ClientFormErrors[field][key] = messages[key];
        }
      }
    }
  }

  private ResetAlertErrorMessages(): void
  {
    this.AlertErrorMessages = [];
  }

  private ScrollToAlert(): void
  {
    const alert_element = document.getElementById('error_messages_alert');
    alert_element.scrollIntoView({block: "end", behavior: "smooth"});
  }

  private UpdateFormValuesAndPopulatePremiseLists(preAuthorizedAccountP?: PreAuthorizedAccountQueryResult): void
  {
    this.PreAuthFormSubmitted = false;
    this.PreAuthForm.reset();

    // If we are in add mode.
    if (!preAuthorizedAccountP)
    {
      this.SelectedPremises = [];
      this.UnselectedPremises = this.Premises.slice();
      return;
    }

    // If we are in update mode.
    this.SelectedPremises = this.Premises.filter(premiseP => {
      return this.PreAuthorizedAccount.PremiseIDs.includes(premiseP.PremiseID);
    });

    this.UnselectedPremises = this.Premises.filter(premiseP => {
      return !this.PreAuthorizedAccount.PremiseIDs.includes(premiseP.PremiseID);
    });


    this.PreAuthForm.setValue({
      bankNumber: PrependLeadingZerosToNumber(preAuthorizedAccountP.BankNumber, 3),
      branchNumber: PrependLeadingZerosToNumber(preAuthorizedAccountP.BranchNumber, 5),
      accountNumber: preAuthorizedAccountP.AccountNumber,
      authorizedByCustomer: false
    });
  }
}
