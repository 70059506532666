
import {mergeMap, tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {BaseApiDataService} from "./base-api-data.service";
import {ReportOutage} from "../models/api/command/report-outage";
import {ReportOutageUnauthenticated} from "../models/api/command/report-outage-unauthenticated";
import {Observable} from "rxjs";
import {OutageModels} from "../models/api/outage-models";
import {OutageSubmissionResults} from "../models/api/enum/outage-submission-result";

@Injectable()
export class OutageDataService
{
  constructor(private baseApiDataService: BaseApiDataService)
  { }

  public get SafetyMessage()
  {
    return this.m_SafetyMessage;
  }

  public get OutageSubmissionStatusCode()
  {
    return this.m_SubmissionCode;
  }

  private m_SafetyMessage: string;
  private m_SubmissionCode: OutageSubmissionResults;

  public ReportAnOutage(reportOutageP: ReportOutage): Observable<OutageModels.ReportOutage>
  {
    return this.baseApiDataService.MakeAuthApiPostRequest<OutageModels.ReportOutage>(`outage/reportoutage`, reportOutageP)
      .pipe(
        tap((resultP: OutageModels.ReportOutage) => {
          // Store details for our redirect to the success page
          if (resultP.IsSuccess)
          {
            this.m_SubmissionCode = resultP.Result.SubmissionCode;
            this.m_SafetyMessage = resultP.Result.SafetyMessage;
          }
        })
      );
  }
  public ReportAnOutageUnauthenticated(reportOutageP: ReportOutageUnauthenticated): Observable<OutageModels.ReportOutageUnauthenticated> {

    return this.baseApiDataService.MakeAuthApiPostRequest<OutageModels.ReportOutage>(`outage/reportoutageunauthenticated`, reportOutageP)
      .pipe(
        tap((resultP: OutageModels.ReportOutageUnauthenticated) => {
          // Store details for our redirect to the success page
          if (resultP.IsSuccess)
          {
            this.m_SubmissionCode = resultP.Result.SubmissionCode;
            this.m_SafetyMessage = resultP.Result.SafetyMessage;
          }
        })
      );
  }
}
