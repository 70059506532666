import {Pipe, PipeTransform} from "@angular/core";
import {PreAuthorizedAccountService} from "../services/pre-authorized-account.service";

@Pipe({
  name: 'meInstitutionName'
})
export class MeInstitutionNamePipe implements PipeTransform
{
  constructor(private preAuthorizedAccountService: PreAuthorizedAccountService)
  { }

  public transform(valueP: number|string): string
  {
    if (!valueP)
    {
      return 'Unknown';
    }

    return this.MapInstitutionNumberToInstitutionName(valueP);
  }

  private MapInstitutionNumberToInstitutionName(institutionNumberP: number|string): string
  {
    const value_to_map = parseInt(institutionNumberP.toString());

    const financial_institution = this.preAuthorizedAccountService.ValidFinancialInstitutions.find(institutionP => institutionP.ID === value_to_map);

    if (!financial_institution)
    {
      return 'Unknown'
    }

    return financial_institution.Name;
  }
}
