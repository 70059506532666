﻿import {Component, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {CustomerDataService} from "../../services/customer-data.service";
import {CustomerModels} from "../../models/api/customer-models";
import {Router} from "@angular/router";
import { Title } from "@angular/platform-browser";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'mp-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit
{
  constructor(private customerDataService: CustomerDataService,
              private router: Router,
              private titleService: Title)
  { }

  public ActiveServerRequest: boolean;
  public RegistrationErrorMessages: string[];
  public ServerConnectionError: boolean;
  public PublicSite: string;
  public ReCaptchaSiteKey = environment.ReCaptchaButtonSiteKey;
  public ReCaptchaToken: string;


  public ngOnInit(): void
  {
    this.titleService.setTitle('Register');
    this.ActiveServerRequest = false;
    this.ResetServerRegistrationErrors();
    this.PublicSite = environment.PublicSite;
  }

  public RegisterNewUser(formP: NgForm): void
  {
    this.ResetServerRegistrationErrors();

    if (formP.invalid)
    {
      return;
    }

    formP.value.CustomerID = formP.value.CustomerID.slice(0, 6); //IDs should only be 6 characters long

    this.ActiveServerRequest = true;

    this.customerDataService.Register(formP.value, this.ReCaptchaToken).subscribe((registrationResultP: CustomerModels.Register) => {
      this.ActiveServerRequest = false;

      if (registrationResultP.IsSuccess)
      {
        this.router.navigate(['/unauthenticated/successful-registration']);
        return;
      }

      this.RegistrationErrorMessages = registrationResultP.ErrorMessages;

      // Timeout so the error messages can render first
      setTimeout(() => {
        this.ScrollToAlert();
      });
    }, (errorP) => {
      this.ReCaptchaToken = "";
      this.ActiveServerRequest = false;
      this.ServerConnectionError = true;
    });
  }

  public ScrollToAlert(): void
  {
    const alert_element = document.getElementById('registration_failure_alert');
    alert_element.scrollIntoView();
  }

  private ResetServerRegistrationErrors(): void
  {
    this.RegistrationErrorMessages = [];
    this.ServerConnectionError = false;
  }
}
