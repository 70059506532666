import {Injectable} from "@angular/core";
import {BaseApiDataService} from "./base-api-data.service";
import {Observable} from "rxjs";
import {CustomerAccountModels} from "../models/api/customer-account-models";
import {JwtService} from "./jwt.service";

@Injectable()
export class CustomerAccountDataService
{
  constructor(private baseApiDataService: BaseApiDataService,
              private jwtService: JwtService)
  { }

  public GetCustomerAccount(premiseIdP: string): Observable<CustomerAccountModels.GetByCustomerAndPremise>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiGetRequest(`customer-account/customer/${customer_id}/premise/${premiseIdP}`);
  }

  public GetWithPreAuthForCustomer(): Observable<CustomerAccountModels.GetWithPreAuthForCustomer>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseApiDataService.MakeAuthApiGetRequest(`customer-accounts/customer/${customer_id}/with-pre-auth`);
  }
}
