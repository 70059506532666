import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'mp-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit
{
  constructor(private titleService: Title)
  { }

  public ngOnInit(): void
  {
    this.titleService.setTitle('Unauthorized');
  }
}
