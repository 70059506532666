import {Injectable} from "@angular/core";
import {PreAuthorizedAccountConfirmationTypes} from "../models/app/enum/pre-authorized-account-confirmation-types";
import {PreAuthorizedAccountSubmission} from "app/models/api/command/pre-authorized-account-submission";
import {PreAuthorizedAccountSubmissionResult} from "../models/api/command-result/pre-authorized-account-submission-result";
import {PreAuthorizedAccountConfirmationModel} from "../models/app/pre-authorized-account-confirmation-model";
import {FinancialInstitution} from "../models/app/financial-institution";

@Injectable()
export class PreAuthorizedAccountService
{
  constructor()
  { }

  public get CachedPreAuthorizedAccountConfirmationModel(): PreAuthorizedAccountConfirmationModel
  {
    return this.m_CachedPreAuthorizedAccountConfirmationModel;
  }

  public get ValidFinancialInstitutions(): FinancialInstitution[]
  {
    // As we don't have control over the tables for ME's database, we are storing this data in the app.
    return [
      {
        "ID": 1,
        "Name": "Bank of Montreal"
      },
      {
        "ID": 2,
        "Name": "The Bank of Nova Scotia"
      },
      {
        "ID": 3,
        "Name": "Royal Bank of Canada"
      },
      {
        "ID": 4,
        "Name": "The Toronto-Dominion Bank"
      },
      {
        "ID": 6,
        "Name": "National Bank of Canada"
      },
      {
        "ID": 10,
        "Name": "Canadian Imperial Bank of Commerce"
      },
      {
        "ID": 16,
        "Name": "HSBC Bank Canada"
      },
      {
        "ID": 30,
        "Name": "Canadian Western Bank"
      },
      {
        "ID": 39,
        "Name": "Laurentian Bank of Canada"
      },
      {
        "ID": 177,
        "Name": "Bank of Canada"
      },
      {
        "ID": 219,
        "Name": "Alberta Treasury Branches"
      },
      {
        "ID": 240,
        "Name": "The Royal Bank of Scotland plc, Canada Branch"
      },
      {
        "ID": 241,
        "Name": "Bank of America, National Association"
      },
      {
        "ID": 242,
        "Name": "The Bank of New York Mellon"
      },
      {
        "ID": 245,
        "Name": "Bank of Tokyo-Mitsubishi UFJ (Canada)"
      },
      {
        "ID": 250,
        "Name": "BNP Paribas"
      },
      {
        "ID": 260,
        "Name": "Citibank Canada"
      },
      {
        "ID": 265,
        "Name": "Deutsche Bank AG"
      },
      {
        "ID": 269,
        "Name": "Mega International Commercial Bank (Canada)"
      },
      {
        "ID": 270,
        "Name": "JPMorgan Chase Bank, National Association"
      },
      {
        "ID": 275,
        "Name": "KEB Hana Bank Canada"
      },
      {
        "ID": 277,
        "Name": "Mizuho Bank Ltd."
      },
      {
        "ID": 290,
        "Name": "UBS Bank (Canada)"
      },
      {
        "ID": 292,
        "Name": "Société Générale (Canada Branch)"
      },
      {
        "ID": 294,
        "Name": "SBI Canada Bank"
      },
      {
        "ID": 301,
        "Name": "Sumitomo Mitsui Banking Corporation, Canada Branch"
      },
      {
        "ID": 303,
        "Name": "Amex Bank of Canada"
      },
      {
        "ID": 307,
        "Name": "Industrial and Commercial Bank of China (Canada)"
      },
      {
        "ID": 308,
        "Name": "Bank of China (Canada)"
      },
      {
        "ID": 309,
        "Name": "Vancity Community Investment Bank"
      },
      {
        "ID": 310,
        "Name": "First Nations Bank of Canada"
      },
      {
        "ID": 311,
        "Name": "BofA Canada Bank"
      },
      {
        "ID": 314,
        "Name": "J.P. Morgan Bank Canada"
      },
      {
        "ID": 315,
        "Name": "CTBC Bank Corp. (Canada)"
      },
      {
        "ID": 318,
        "Name": "U.S. Bank National Association"
      },
      {
        "ID": 320,
        "Name": "President's Choice Bank"
      },
      {
        "ID": 321,
        "Name": "Habib Canadian Bank"
      },
      {
        "ID": 322,
        "Name": "Rabobank Canada"
      },
      {
        "ID": 323,
        "Name": "Capital One Bank (Canada Branch)"
      },
      {
        "ID": 326,
        "Name": "Canadian Imperial Bank of Commerce"
      },
      {
        "ID": 327,
        "Name": "Slate Street"
      },
      {
        "ID": 328,
        "Name": "Citibank, N.A"
      },
      {
        "ID": 330,
        "Name": "Comerica Bank"
      },
      {
        "ID": 332,
        "Name": "First Commercial Bank"
      },
      {
        "ID": 334,
        "Name": "VersaBank"
      },
      {
        "ID": 335,
        "Name": "United Overseas Bank Limited"
      },
      {
        "ID": 336,
        "Name": "Maple Bank"
      },
      {
        "ID": 339,
        "Name": "UBS AG Canada Branch"
      },
      {
        "ID": 340,
        "Name": "ICICI Bank Canada"
      },
      {
        "ID": 342,
        "Name": "Zag Bank"
      },
      {
        "ID": 343,
        "Name": "Hollis Canadian Bank"
      },
      {
        "ID": 344,
        "Name": "General Bank of Canada"
      },
      {
        "ID": 345,
        "Name": "Fifth Third Bank"
      },
      {
        "ID": 346,
        "Name": "Société Générale (Canada Branch)"
      },
      {
        "ID": 347,
        "Name": "Bridgewater Bank"
      },
      {
        "ID": 349,
        "Name": "The Northern Trust Company, Canada Branch"
      },
      {
        "ID": 352,
        "Name": "DirectCash Bank"
      },
      {
        "ID": 355,
        "Name": "Shinhan Bank Canada"
      },
      {
        "ID": 356,
        "Name": "Citco Bank Canada"
      },
      {
        "ID": 357,
        "Name": "M&T Bank"
      },
      {
        "ID": 358,
        "Name": "HomEquity Bank"
      },
      {
        "ID": 359,
        "Name": "Walmart Canada Bank"
      },
      {
        "ID": 360,
        "Name": "Barclays Bank PLC, Canada Branch"
      },
      {
        "ID": 361,
        "Name": "Home Bank"
      },
      {
        "ID": 362,
        "Name": "Wells Fargo Bank, National Association, Canadian Branch"
      },
      {
        "ID": 363,
        "Name": "Royal Bank of Scotland N.V., (Canada) Branch"
      },
      {
        "ID": 364,
        "Name": "Continental Bank of Canada"
      },
      {
        "ID": 365,
        "Name": "PNC Bank Canada Branch"
      },
      {
        "ID": 366,
        "Name": "China Construction Bank Toronto Branch"
      },
      {
        "ID": 368,
        "Name": "Rogers Bank"
      },
      {
        "ID": 370,
        "Name": "Wealth One Bank of Canada"
      },
      {
        "ID": 372,
        "Name": "Bank of China, Toronto Branch"
      },
      {
        "ID": 373,
        "Name": "Sumitomi Mitsui Banking Corporation of Canada"
      },
      {
        "ID": 376,
        "Name": "Exchange Bank of Canada"
      },
      {
        "ID": 378,
        "Name": "Cidel Bank Canada"
      },
      {
        "ID": 507,
        "Name": "Community Trust Company"
      },
      {
        "ID": 509,
        "Name": "The Canada Trust Company"
      },
      {
        "ID": 522,
        "Name": "Trust La Laurentienne du Canada Inc."
      },
      {
        "ID": 532,
        "Name": "Effort Trust Company"
      },
      {
        "ID": 536,
        "Name": "Investors Group Trust Co. Ltd."
      },
      {
        "ID": 540,
        "Name": "Manulife Bank of Canada"
      },
      {
        "ID": 548,
        "Name": "CIBC Trust Corporation"
      },
      {
        "ID": 550,
        "Name": "Montreal Trust Company of Canada"
      },
      {
        "ID": 551,
        "Name": "Sun Life Financial Trust Inc."
      },
      {
        "ID": 568,
        "Name": "Peace Hills Trust Company"
      },
      {
        "ID": 570,
        "Name": "The Royal Trust Company"
      },
      {
        "ID": 580,
        "Name": "Royal Trust Corporation of Canada"
      },
      {
        "ID": 590,
        "Name": "National Trust Company"
      },
      {
        "ID": 597,
        "Name": "TD Mortgage Corporation"
      },
      {
        "ID": 603,
        "Name": "TD Pacific Mortgage Corporation"
      },
      {
        "ID": 604,
        "Name": "HSBC Mortgage Corporation (Canada)"
      },
      {
        "ID": 606,
        "Name": "Scotia Mortgage Corporation"
      },
      {
        "ID": 608,
        "Name": "CS Alterna Bank"
      },
      {
        "ID": 614,
        "Name": "Tangerine Bank"
      },
      {
        "ID": 618,
        "Name": "B2B Bank"
      },
      {
        "ID": 621,
        "Name": "Peoples Trust Company"
      },
      {
        "ID": 623,
        "Name": "Equitable Bank"
      },
      {
        "ID": 625,
        "Name": "Industrielle Alliance, Fiducie inc."
      },
      {
        "ID": 626,
        "Name": "Manulife Trust Company"
      },
      {
        "ID": 627,
        "Name": "Home Trust Company"
      },
      {
        "ID": 701,
        "Name": "Edward Jones"
      },
      {
        "ID": 703,
        "Name": "Wealthsimple"
      },
      {
        "ID": 803,
        "Name": "Latvian Credit Union Limited"
      },
      {
        "ID": 809,
        "Name": "Central 1 Credit Union"
      },
      {
        "ID": 815,
        "Name": "Fédération des caisses Desjardins du Quebec"
      },
      {
        "ID": 819,
        "Name": "Caisse Populaire Group Financier Ltée"
      },
      {
        "ID": 828,
        "Name": "Central 1 Credit Union"
      },
      {
        "ID": 829,
        "Name": "La Fédération des caisses populaires de l'Ontario Inc."
      },
      {
        "ID": 839,
        "Name": "Atlantic Central"
      },
      {
        "ID": 840,
        "Name": "Dundalk District Credit Union Limited"
      },
      {
        "ID": 849,
        "Name": "Atlantic Central"
      },
      {
        "ID": 853,
        "Name": "Concentra Bank"
      },
      {
        "ID": 854,
        "Name": "Golden Horseshoe Credit Union Limited"
      },
      {
        "ID": 865,
        "Name": "Caisse populaire acadienne ltée"
      },
      {
        "ID": 869,
        "Name": "Central 1 Credit Union"
      },
      {
        "ID": 879,
        "Name": "Credit Union  Central of Manitoba Limited"
      },
      {
        "ID": 889,
        "Name": "Credit Union Central of Saskatchewan"
      },
      {
        "ID": 890,
        "Name": "L'Alliance des caisses populaires de l'Ontario, Ltée."
      },
      {
        "ID": 899,
        "Name": "Credit Union Central Alberta Limited"
      }
    ];
  }

  private m_CachedPreAuthorizedAccountConfirmationModel: PreAuthorizedAccountConfirmationModel;

  public CachePreAuthorizedAccountConfirmation(confirmationTypeP: PreAuthorizedAccountConfirmationTypes, accountSubmissionP: PreAuthorizedAccountSubmission, submissionResultP: PreAuthorizedAccountSubmissionResult): void
  {
    this.m_CachedPreAuthorizedAccountConfirmationModel = {
      Result: submissionResultP,
      Submission: accountSubmissionP,
      Type: confirmationTypeP
    }
  }
}
