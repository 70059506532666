import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CustomerAccountWithPreAuthQueryResult} from "../../models/api/query-result/customer-account-pre-auth-query-result";
import {ModalDirective} from "ngx-bootstrap";

@Component({
  selector: 'mp-premise-selection-modal',
  templateUrl: './premise-selection-modal.component.html',
  styleUrls: ['./premise-selection-modal.component.scss']
})
export class PremiseSelectionModalComponent implements OnInit
{
  @Input('premises')
  public Premises: CustomerAccountWithPreAuthQueryResult;

  @Output('premiseConfirmed')
  public PremiseConfirmedEvent = new EventEmitter<CustomerAccountWithPreAuthQueryResult>();

  @ViewChild('premiseSelectionModal')
  public PremiseSelectionModal: ModalDirective;

  constructor()
  { }

  public SelectedPremise: CustomerAccountWithPreAuthQueryResult;

  public ngOnInit(): void
  { }

  public ConfirmPremise()
  {
    if (this.SelectedPremise)
    {
      this.PremiseConfirmedEvent.emit(this.SelectedPremise);
    }

    this.HideModal();
  }

  public HideModal(): void
  {
    // Clear the selected premise when we hide
    this.SelectedPremise = null;

    this.PremiseSelectionModal.hide();
  }

  public ShowModal(): void
  {
    this.PremiseSelectionModal.show();
  }
}
