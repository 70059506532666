import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { RecoverDataService } from "../../services/recover-data.service";
import { NgForm } from "@angular/forms";
import { ResetPasswordWithRecoveryCode } from "../../models/api/command/reset-password-with-recovery-code";
import {Title} from "@angular/platform-browser";
import { environment } from 'environments/environment';

@Component({
  selector: 'mp-choose-new-password-for-recovery',
  templateUrl: './choose-new-password-for-recovery.component.html',
  styleUrls: ['./choose-new-password-for-recovery.component.scss']
})
export class ChooseNewPasswordForRecoveryComponent implements OnInit
{
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private recoverDataService: RecoverDataService,
              private titleService: Title)
  { }

  public ErrorMessages: string[];
  public ServerConnectionError: boolean;
  public SuccessMessage: string;
  public SubmittingChange: boolean;
  public ReCaptchaSiteKey = environment.ReCaptchaButtonSiteKey;
  public ReCaptchaToken: string;

  private m_RecoveryKey: string;

  public ngOnInit(): void
  {
    this.titleService.setTitle('Choose new Password');
    this.ErrorMessages = [];
    this.SubmittingChange = false;

    this.activatedRoute.queryParams.subscribe(queryParamsP => {
      const key = queryParamsP['key'];

      if (!key)
      {
        this.router.navigate(['/recover/send-recovery-email']);
        return;
      }

      this.m_RecoveryKey = key;
    })
  }

  public ResetPassword(formP: NgForm): void
  {
    this.ServerConnectionError = false;
    this.ErrorMessages = [];

    if (formP.invalid)
    {
      return;
    }

    const reset_model: ResetPasswordWithRecoveryCode = {
      RecoveryCode: this.m_RecoveryKey,
      Password: formP.value.Password,
      PasswordConfirm: formP.value.PasswordConfirm
    };

    this.SubmittingChange = true;
    this.recoverDataService.ResetPassword(reset_model, this.ReCaptchaToken).subscribe(resultP => {
      this.SubmittingChange = false;

      if (resultP.IsSuccess)
      {
        this.SuccessMessage = "Password has been reset!";
      }

      this.ErrorMessages = resultP.ErrorMessages;
    }, () => {
      this.SubmittingChange = false;
      this.ServerConnectionError = true;
    });
  }
}
