import { Injectable } from "@angular/core";
import { BaseApiDataService } from "./base-api-data.service";
import { Observable } from "rxjs";
import { RecoverModels } from "../models/api/recover-models";
import { SendRecoveryEmail } from "../models/api/command/send-recovery-email";
import { ResetPasswordWithRecoveryCode } from 'app/models/api/command/reset-password-with-recovery-code';

@Injectable()
export class RecoverDataService
{
  constructor(private baseApiDataService: BaseApiDataService)
  { }

  public SendRecoveryEmail(emailP: SendRecoveryEmail, reCaptchaToken: string): Observable<RecoverModels.SendRecoveryEmail>
  {
    return this.baseApiDataService.MakeAuthApiPostRequest(`recover/send-recovery-email`, emailP, { "RECAPTCHA_TOKEN": reCaptchaToken });
  }

  public ResetPassword(resetModelP: ResetPasswordWithRecoveryCode, recaptchaTokenP: string): Observable<RecoverModels.ResetPassword>
  {
    return this.baseApiDataService.MakeAuthApiPostRequest(`recover/reset-password`, resetModelP, { "RECAPTCHA_TOKEN": recaptchaTokenP });
  }
}
