﻿import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { FormsService } from "../../services/forms.service";
import { FormModels } from "../../models/api/form-models";
import { Router } from "@angular/router";

@Component({
  selector: 'mp-disconnect-request',
  templateUrl: './disconnect-request.component.html',
  styleUrls: ['./disconnect-request.component.scss']
})
export class DisconnectRequestComponent implements OnInit {

  constructor(private formsService: FormsService,
    private router: Router) { }

  public DisconnectErrorMessages: string[];
  public ServerConnectionError: boolean;
  public ActiveServerRequest: boolean;

  public StartDate: Date = new Date();
  public MinDate: Date = void 0;

  ngOnInit() {
    this.ResetServerFormErrors();
  }

  public SendToApi(formP: NgForm): void {

    //If form is invalid, we exit.
    if (formP.invalid) {
      return;
    }
    
    //Send Form Results to FormsService
    this.formsService.Disconnect(formP.value).subscribe((disconnectResultP: FormModels.Disconnect) => {
      this.ActiveServerRequest = false;

      if (disconnectResultP.IsSuccess) {
        this.router.navigate(['/forms/success']);
      }

      if (disconnectResultP.IsFailure) {
        this.router.navigate(['/forms/error']);
      }

      this.DisconnectErrorMessages = disconnectResultP.ErrorMessages;

    }, (errorP) => {
      this.ActiveServerRequest = false;
      this.ServerConnectionError = true;
      this.router.navigate(['/forms/error']);
      return;
    });
  }

  private ResetServerFormErrors(): void {
    this.DisconnectErrorMessages = [];
    this.ServerConnectionError = false;
  }
}