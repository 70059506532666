﻿import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Observable } from "rxjs";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { UpdateAccountService } from "../../services/update-account.service";
import { FormsService } from "../../services/forms.service";
import { FormModels } from "../../models/api/form-models";
import { Arrears } from "../../models/api/command/arrears";
import { Location } from "../../models/api/command/location";
import { AccountDataService } from "../../services/account-data.service";
import { SendArrearsEmail } from "../../models/api/command/send-arrears-email";

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const locationNames = ["BANK OF MONTREAL", "BANK OF NOVA SCOTIA", "CENTRAL CREDIT UNION", "CENTRAL GUARANTEE", "CIBC", "CONSOLIDATED CREDIT UNION", "CSA", "EAST ROYALTY PHARMACY", "EVANGELINE CREDIT UNION", "FOODLAND", "MAIL", "MALPEQUE CREDIT UNION", "MECL - CHARLOTTETOWN", "METRO CREDIT UNION", "MONTAGUE CREDIT UNION", "MORELL CONSUMERS COOP", "MORELL CREDIT UNION", "MURPHYS CORNWALL PHARMACY", "MURPHYS PHARMACY - KENSINGTON", "MURPHYS PHARMACY - PARKDALE", "MURPHYS PHARMACY - QUEEN ST", "MURPHYS PHARMACY - STRATFORD", "MURPHYS PHARMACY - WR", "NATIONAL BANK", "PAYMENTUS", "PC FINANCIAL", "QUEEN STREET PHARMACY", "ROYAL BANK", "SHERWOOD DRUG", "SLEMON PARK", "SOBEYS - CHARLOTTETOWN - ALLEN", "SOBEYS - CHARLOTTETOWN - WR", "SOBEYS - MONTAGUE", "SOBEYS - STRATFORD", "SOBEYS - SUMMERSIDE", "SOURIS CREDIT UNION", "STELLA MARIS CREDIT UNION", "TD CANADA TRUST", "THE SUPPLY CUPBOARD", "TIGNISH COOP", "TIGNISH CREDIT UNION", "TORONTO DOMINION", "WEST ROYALTY IRVING", "WINSLOE IRVING"];

@Component({
  selector: 'mp-arrears',
  templateUrl: './arrears.component.html',
  styleUrls: ['./arrears.component.scss']
})

export class ArrearsComponent implements OnInit {
  constructor(private accountDataService: AccountDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private updateAccountService: UpdateAccountService,
    private ref: ChangeDetectorRef
    )
  { }

  public ErrorMessage: string;
  public ServerConnectionError: boolean;
  public SubmittingChange: boolean;
  public StatusMessage: string;
  public ArrearsDetails: Arrears;
  public LoadingApiData: boolean;
  public LocationNames: string[] = locationNames;
  public PaymentLocation: string = "";

  public PayBalanceOption: boolean = undefined;
  public PayArrearsOption: boolean = undefined;
  public Pay60And90DayArrearsOption: boolean = undefined;

  private Href: string = "";
  private PremiseId: string = "000000";
  private EmailSentToClient = false;
  private HasSubmitted = false;

  public ngOnInit(): void {
    this.titleService.setTitle('Arrears Payment Arrangements');
    this.LoadingApiData = true;
    this.StatusMessage = "";
    this.updateAccountService.ResetAccountUpdatedMessage();
    this.SubmittingChange = false;
    this.Href = this.router.url;
    this.PremiseId = this.Href.split('/')[2]; //EX: www.domain.com/premise/000455/arrears -> 000455

    this.GenerateArrangementData(false);
  }

  private GenerateArrangementData(emailNewData: boolean): void
  {
    this.accountDataService.GetArrears(this.PremiseId).subscribe(x => {
      this.LoadingApiData = false;

      if (x.IsSuccess) {
        this.ArrearsDetails = x.Result;
        this.ArrearsDetails.AccountDue = new Date(Number(x.Result.AccountDue.toString().substring(6, x.Result.AccountDue.toString().length - 7)));
        this.ArrearsDetails.ArrangementDate = new Date(Number(x.Result.ArrangementDate.toString().substring(6, x.Result.ArrangementDate.toString().length - 7)));

        if(this.ArrearsDetails.AccountDue.getFullYear() + 10 < new Date().getFullYear())
          this.ArrearsDetails.AccountDue.setDate(new Date().getDate() + 30);

        if (emailNewData) {
          //START: Send email to customer service
          const submission_model: SendArrearsEmail = {
            ArrangementId: this.ArrearsDetails.ArrangementId,
            ArrangementDate: this.ArrearsDetails.ArrangementDate,
            Arrangement: this.GenerateArrearsMessage(this.ArrearsDetails, true),
            EmailAddress: "CustomerServiceOnlinePaymentArrangement@maritimeelectric.com"
          };

          this.accountDataService.SendArrearsEmail(submission_model, this.PremiseId).subscribe(resultP => { });
          //END: Send email to customer sevice
        }
      }
    }, () => {
      this.LoadingApiData = false;
      this.ServerConnectionError = true;
      });
  }

  public SubmitForm(formP: NgForm): void {
    this.ErrorMessage = "";
    this.ServerConnectionError = false;

    if (formP.invalid) {
      return;
    }

    this.ArrearsDetails.HasActiveArrangement = true; //arrears arrangement has just been set up.

    //START: What amount is being paid?
    if (this.PayBalanceOption)
      this.ArrearsDetails.PaymentAmount = this.ArrearsDetails.AmountOwed;
    else if (this.PayArrearsOption)
      this.ArrearsDetails.PaymentAmount = this.ArrearsDetails.AmountArrears;
    else if (this.Pay60And90DayArrearsOption)
      this.ArrearsDetails.PaymentAmount = this.ArrearsDetails.Amount60And90DayArrears;
    //END: What amount is being paid?

    this.ArrearsDetails.PaymentLocation = this.PaymentLocation;

    this.ArrearsDetails.ArrangementMsg = this.GenerateArrearsMessage(this.ArrearsDetails, false);
    this.SubmittingChange = true;

    if (!this.HasSubmitted) { //stop user from submitting multiple times
      this.HasSubmitted = true;
      this.accountDataService.Arrears(this.ArrearsDetails, this.PremiseId).subscribe(resultP => {
        this.SubmittingChange = false;

        if (resultP.IsSuccess) {
          this.StatusMessage = "Your arrangement has been created.";
          this.router.navigate([this.Href]);

          this.GenerateArrangementData(true);

          return;
        }

        this.ErrorMessage = "An error has occurred. Please contact customer service.";
      }, () => {
        this.SubmittingChange = false;
        this.ServerConnectionError = true;
      })
    }
  }

  public Email(formP: NgForm): void {
    this.ErrorMessage = "";
    this.StatusMessage = "";

    if (formP.invalid) {
      return;
    }

    this.SubmittingChange = true;
    const submission_model: SendArrearsEmail = {
      ArrangementId: this.ArrearsDetails.ArrangementId,
      ArrangementDate: this.ArrearsDetails.ArrangementDate,
      Arrangement: this.GenerateArrearsMessage(this.ArrearsDetails, true),
      EmailAddress: this.ArrearsDetails.EmailAddress
    };

    this.accountDataService.SendArrearsEmail(submission_model, this.PremiseId).subscribe(resultP => {
      this.SubmittingChange = false;

      if (resultP.Result) {
        this.titleService.setTitle('Arrangement Details Email Sent');
        this.StatusMessage = "The details of your arrangement have been sent to your email.";
        this.EmailSentToClient = true;
      }
    }, () => {
      this.SubmittingChange = false;
    })
  }

  private GenerateArrearsMessage(arrearsInfo: Arrears, emailSafeMessage: boolean): string
  {
    var arrearsMessage: string = "The following payment arrangement(s) have been entered for this customer. Total amount agreed to be paid is ";

    if (!emailSafeMessage) {

      if (arrearsInfo.AccountDue == null)
      {
        var now = new Date();
        if (now.getMonth() == 11) {
          arrearsInfo.AccountDue = new Date(now.getFullYear() + 1, 0, 1);
        } else {
          arrearsInfo.AccountDue = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        }
      }

      if (this.PayBalanceOption)
        arrearsMessage += arrearsInfo.AmountOwed + ".";
      else if (this.PayArrearsOption)
        arrearsMessage += arrearsInfo.AmountArrears + ".";
      else if (this.Pay60And90DayArrearsOption)
        arrearsMessage += arrearsInfo.Amount60And90DayArrears + ".";
    }
    else
    {
      arrearsMessage = ""; //asked to send minimal info via email
    }

    return arrearsMessage;
  }

  public IsNull(obj): boolean
  {
    var isNull = false;

    if (obj === undefined)
      isNull = true;

    return isNull;
  }
}