﻿import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "../../components/login/login.component";
import {AuthGuard} from "./guards/auth-guard";
import {AuthenticatedComponent} from "../../components/authenticated/authenticated.component";
import {UnAuthenticatedComponent} from "../../components/un-authenticated/un-authenticated.component";
import {RegistrationComponent} from "../../components/registration/registration.component";
import {SuccessfulRegistrationComponent} from "../../components/successful-registration/successful-registration.component";
import {SelectAccountComponent} from "../../components/select-account/select-account.component";
import {HomeComponent} from "../../components/home/home.component";
import {PlannedOutagesComponent} from "../../components/planned-outages/planned-outages.component";
import {ReportAnOutageComponent} from "../../components/report-an-outage/report-an-outage.component";
import {OutageReportedComponent} from "../../components/outage-reported/outage-reported.component";
import {PastBillsComponent} from "../../components/past-bills/past-bills.component";
import {PastTransactionsComponent} from "../../components/past-transactions/past-transactions.component";
import {DisconnectNoticesComponent} from "../../components/disconnect-notices/disconnect-notices.component";
import {AccountComponent} from "../../components/account/account.component";
import {ChangePasswordComponent} from "../../components/change-password/change-password.component";
import {ChangeEmailComponent} from "../../components/change-email/change-email.component";
import {ChangePhonesComponent} from "app/components/change-phones/change-phones.component";
import { ChangeBillDeliveryTypeComponent } from "../../components/change-bill-delivery-type/change-bill-delivery-type.component";
import { SendRecoveryEmailComponent } from "../../components/send-recovery-email/send-recovery-email.component";
import { ChooseNewPasswordForRecoveryComponent } from "../../components/choose-new-password-for-recovery/choose-new-password-for-recovery.component";
import {ManagePreAuthorizedAccountsComponent} from "../../components/manage-pre-authorized-accounts/manage-pre-authorized-accounts.component";
import {PreAuthorizedAccountComponent} from "../../components/pre-authorized-account/pre-authorized-account.component";
import {UnauthorizedComponent} from "app/components/unauthorized/unauthorized.component";
import {NotFoundComponent} from "../../components/not-found/not-found.component";
import { PreAuthorizedAccountConfirmationComponent } from "../../components/pre-authorized-account-confirmation/pre-authorized-account-confirmation.component";
import { NewServiceRequestComponent } from "../../components/new-service-request/new-service-request.component";
import { DisconnectRequestComponent } from "../../components/disconnect-request/disconnect-request.component";
import { BookPresentationComponent } from "../../components/book-presentation/book-presentation.component";
import { CommentCardComponent } from "../../components/comment-card/comment-card.component";
import { StaycationContestComponent } from "../../components/staycation-contest/staycation-contest.component";
import { DeferredPaymentsComponent } from "../../components/deferred-payments/deferred-payments.component";
import { ServiceRequestErrorComponent } from "../../components/service-request-error/service-request-error.component";
import { ServiceRequestContactMeclComponent } from "../../components/service-request-contact-mecl/service-request-contact-mecl.component";
import { ServiceRequestSuccessComponent } from "../../components/service-request-success/service-request-success.component";
import { RequestDonationComponent } from "../../components/request-donation/request-donation.component";
import { StreetlightRepairComponent } from "../../components/streetlight-repair/streetlight-repair.component";
import { TreeTrimmingComponent } from "../../components/tree-trimming/tree-trimming.component";
import { ReportAnOutageUnauthenticatedComponent } from "../../components/report-an-outage-unauthenticated/report-an-outage-unauthenticated.component";
import { PublicLoginUnauthenticatedComponent } from "../../components/public-login-unauthenticated/public-login-unauthenticated.component";
import { ArrearsComponent } from "../../components/arrears/arrears.component";
import { ServiceRequestsComponent } from 'app/components/service-requests/service-requests.component';

const routes: Routes = [{
  path: '',
  component: AuthenticatedComponent,
  canActivate: [AuthGuard],
  children: [{
    path: '',
    component: HomeComponent
  }, {
    path: 'select-account',
    component: SelectAccountComponent
  }, { 
    path: 'service-requests',
    component: ServiceRequestsComponent
  }, {
    path: 'planned-outages',
    component: PlannedOutagesComponent
  }, {
    path: 'report-an-outage',
    component: ReportAnOutageComponent
  }, {
    path: 'outage-reported',
    component: OutageReportedComponent
  }, {
    path: 'premise/:premiseId/past-bills',
    component: PastBillsComponent
  }, {
    path: 'premise/:premiseId/past-transactions',
    component: PastTransactionsComponent
  }, {
    path: 'premise/:premiseId/disconnect-notices',
    component: DisconnectNoticesComponent
  }, {
    path: 'account',
    component: AccountComponent
  }, {
    path: 'account/change-password',
    component: ChangePasswordComponent
  }, {
    path: 'account/change-email',
    component: ChangeEmailComponent
  }, {
    path: 'account/change-phones',
    component: ChangePhonesComponent
  }, {
    path: 'premise/:premiseId/arrears',
    component: ArrearsComponent
  }, {
    path: 'account/change-bill-delivery-type',
    component: ChangeBillDeliveryTypeComponent
  }, {
    path: 'manage-pre-authorized-accounts',
    component: ManagePreAuthorizedAccountsComponent
  }, {
    path: 'pre-authorized-account',
    component: PreAuthorizedAccountComponent
  }, {
    path: 'pre-authorized-account-confirmation',
    component: PreAuthorizedAccountConfirmationComponent
  }, {
    path: 'pre-authorized-account/:premiseId',
    component: PreAuthorizedAccountComponent
  }, {
    path: 'unauthorized',
    component: UnauthorizedComponent
  }]
}, {
  path: 'unauthenticated',
  component: UnAuthenticatedComponent,
  children: [{
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegistrationComponent
  }, {
    path: 'successful-registration',
    component: SuccessfulRegistrationComponent
  }, {
    path: 'recover/send-recovery-email',
    component: SendRecoveryEmailComponent
  }, {
    path: 'recover/password',
    component: ChooseNewPasswordForRecoveryComponent
  }]
}, {
  path: 'forms',
  children: [{
      path: 'success',
      component: ServiceRequestSuccessComponent
    },
    {
      path: 'error',
      component: ServiceRequestErrorComponent
    },
    {
      path: 'contact-mecl',
      component: ServiceRequestContactMeclComponent
    },
    {
      path: 'disconnect',
      component: DisconnectRequestComponent
    },
    {
      path: 'new-service',
      component: NewServiceRequestComponent
    },
    {
      path: 'book-presentation',
      component: BookPresentationComponent
    },
    {
      path: 'comment-card',
      component: CommentCardComponent
    },
    {
      path: 'staycation-contest',
      component: StaycationContestComponent
    },
    {
      path: 'deferred-payments',
      component: DeferredPaymentsComponent
    },
    {
      path: 'request-donation',
      component: RequestDonationComponent
    },
    {
      path: 'streetlight-repair',
      component: StreetlightRepairComponent
    },
    {
      path: 'tree-trimming',
      component: TreeTrimmingComponent
    },
    {
      path: 'report-an-outage',
      component: ReportAnOutageUnauthenticatedComponent
    },
    {
      path: 'public-login-unauthenticated',
      component: PublicLoginUnauthenticatedComponent
    }]
  }, {
  path: '**',
  component: NotFoundComponent
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: [],
  providers: [
    AuthGuard
  ]
})
export class RoutingModule
{ }

