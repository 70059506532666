
import {mergeMap, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {BaseApiDataService} from "./base-api-data.service";
import {Observable} from "rxjs";
import {CustomerModels} from "../models/api/customer-models";
import {UserRegistration} from "../models/api/command/user-registration";
import {SwitchActiveCustomer} from "../models/api/command/switch-active-customer";
import {JwtService} from "./jwt.service";
import {CustomerQueryResult} from "../models/api/query-result/customer-query-result";
import {PhoneTypeQueryResult} from "../models/api/query-result/phone-type-query-result";

@Injectable()
export class CustomerDataService
{
  constructor(private baseDataService: BaseApiDataService,
              private jwtService: JwtService)
  { }


  public AddPhoneTypeNamesToCustomer(customerP: CustomerQueryResult, phoneTypesP: PhoneTypeQueryResult[]): void
  {
    if (!customerP || !phoneTypesP)
    {
      return;
    }

    if (customerP.PhoneNumberType)
    {
      const phone_type = phoneTypesP.find(x => x.ID === customerP.PhoneNumberType);

      if (phone_type)
      {
        customerP.PhoneNumberTypeName = phone_type.Name;
      }
    }

    if (customerP.PhoneNumber2Type)
    {
      const phone_type2 = phoneTypesP.find(x => x.ID === customerP.PhoneNumber2Type);

      if (phone_type2)
      {
        customerP.PhoneNumber2TypeName = phone_type2.Name;
      }
    }

    if (customerP.PhoneNumber3Type)
    {
      const phone_type3 = phoneTypesP.find(x => x.ID === customerP.PhoneNumber3Type);

      if (phone_type3)
      {
        customerP.PhoneNumber3TypeName = phone_type3.Name;
      }
    }
  }

  public Register(registrationModelP: UserRegistration, reCaptchaToken: string): Observable<CustomerModels.Register>
  {
    return this.baseDataService.MakeAuthApiPostRequest('customer/register', registrationModelP, { "RECAPTCHA_TOKEN": reCaptchaToken });
  }

  public GetAssociatedCustomers(): Observable<CustomerModels.GetAssociatedCustomers>
  {
    // Fetch the customer ID from the app state service
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;
    const customer_email = this.jwtService.CurrentAuthTokenPayload.Email;

    return this.baseDataService.MakeAuthApiGetRequest(`customer/${customer_id}/${customer_email}/associated-customers`);
  }

  public GetById(): Observable<CustomerModels.GetById>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseDataService.MakeAuthApiGetRequest(`customer/${customer_id}`);
  }

  public GetHydratedById(): Observable<CustomerModels.GetHydratedById>
  {
    const customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;

    return this.baseDataService.MakeAuthApiGetRequest(`customer/${customer_id}/hydrated`);
  }

  public SwitchActiveCustomer(nextCustomerIdP: string): Observable<CustomerModels.SwitchActiveCustomer>
  {
    const current_customer_id = this.jwtService.CurrentAuthTokenPayload.CustomerID;
    const email = this.jwtService.CurrentAuthTokenPayload.Email;

    const switch_active_customer: SwitchActiveCustomer = {
      CurrentCustomerID: current_customer_id,
      CustomerEmail: email,
      NextCustomerID: nextCustomerIdP
    };

    return this.baseDataService.MakeAuthApiPostRequest<CustomerModels.SwitchActiveCustomer>(`customer/switch-active-customer`, switch_active_customer)
      .pipe(tap(resultP => {
        const token = resultP.Result.Token;

        if (resultP.IsSuccess && token)
        {
          this.jwtService.SetAuthToken(token);
        }
      })
    );
  }
}
