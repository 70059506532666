﻿import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { UserAuthenticationService } from "../../services/user-authentication.service";
import { Title } from "@angular/platform-browser";
import { environment } from '../../../environments/environment';
import * as Cookies from 'js-cookie';

@Component({
  selector: 'mp-public-login-unauthenticated',
  templateUrl: './public-login-unauthenticated.component.html',
  styleUrls: ['./public-login-unauthenticated.component.scss']
})
export class PublicLoginUnauthenticatedComponent implements OnInit {
  constructor(
    private userAuthenticationService: UserAuthenticationService,
    private titleService: Title)
  { }


  public LoginErrorMessage: string;
  public ServerConnectionError: boolean;
  public WaitingOnServerResponse: boolean;
  public ReCaptchaSiteKey = environment.ReCaptchaButtonSiteKey;
  public ReCaptchaToken: string;

  public ngOnInit(): void {
    this.titleService.setTitle('Log In');
    this.WaitingOnServerResponse = false;
    this.LoginErrorMessage = "";
  }

  public LogIn(formP: NgForm): void {
    this.LoginErrorMessage = "";
    this.ServerConnectionError = false;

    if (formP.invalid) {
      return;
    }

    const user_authentication = formP.value;
    this.WaitingOnServerResponse = true;

    this.userAuthenticationService.LogIn(user_authentication, this.ReCaptchaToken).subscribe((responseP) => {

        setTimeout(() => {
          this.WaitingOnServerResponse = false;
          window.top.location.href = `${environment.HostProtocol}${window.location.host}`;
        }, 500);
      },
      (errorP) => {
        this.WaitingOnServerResponse = false;
        this.ReCaptchaToken = "";

        if (errorP.status === 401) {
          this.LoginErrorMessage = errorP.error.Result.ErrorMessage;
          return;
        }

        this.ServerConnectionError = true;
      });
  }
}
