import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'mp-listed-alert',
  templateUrl: './listed-alert.component.html',
  styleUrls: ['./listed-alert.component.scss']
})
export class ListedAlertComponent implements OnInit
{
  @Input('type')
  public Type: string;

  @Input('messages')
  public Messages: string[];

  constructor()
  { }

  public ngOnInit(): void
  { }
}
