import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {CustomerAccountWithPreAuthQueryResult} from "../../models/api/query-result/customer-account-pre-auth-query-result";
import {ModalDirective} from "ngx-bootstrap";

@Component({
  selector: 'mp-confirm-premise-removal-modal',
  templateUrl: './confirm-premise-removal-modal.component.html',
  styleUrls: ['./confirm-premise-removal-modal.component.scss']
})
export class ConfirmPremiseRemovalModalComponent implements OnInit
{
  @Output('removalConfirmed')
  public RemovalConfirmedEvent = new EventEmitter<CustomerAccountWithPreAuthQueryResult>();

  @ViewChild('confirmPremiseRemovalModal')
  public ConfirmPremiseRemovalModal: ModalDirective;

  constructor()
  { }

  public PremiseToRemove: CustomerAccountWithPreAuthQueryResult;

  public ngOnInit(): void
  { }

  public ConfirmPremiseRemoval()
  {
    if (this.PremiseToRemove)
    {
      this.RemovalConfirmedEvent.emit(this.PremiseToRemove);
    }

    this.HideModal();
  }

  public HideModal(): void
  {
    // Clear the selected premise when we hide
    this.PremiseToRemove = null;

    this.ConfirmPremiseRemovalModal.hide();
  }

  public ShowModal(premiseToRemoveP: CustomerAccountWithPreAuthQueryResult): void
  {
    this.PremiseToRemove = premiseToRemoveP;

    this.ConfirmPremiseRemovalModal.show();
  }
}
