import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mp-server-error-alert',
  templateUrl: './server-error-alert.component.html',
  styleUrls: ['./server-error-alert.component.scss']
})
export class ServerErrorAlertComponent implements OnInit
{
  constructor()
  { }

  public ngOnInit(): void
  { }
}
