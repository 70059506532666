﻿import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { FormsService } from "../../services/forms.service";
import { FormModels } from "../../models/api/form-models";
import { Router } from "@angular/router";
import { MyDatePickerModule } from '../angular2-datepicker';

@Component({
  selector: 'mp-new-service-request',
  templateUrl: './new-service-request.component.html',
  styleUrls: [
    './new-service-request.component.scss',
    './styles.css'
  ]
})
export class NewServiceRequestComponent implements OnInit {

  constructor(private formsService: FormsService,
    private router: Router) { }

  public NewServiceErrorMessages: string[];
  public ServerConnectionError: boolean;
  public ActiveServerRequest: boolean;
  public TodaysDate: Date = new Date();
  public UserSelectedYear: string;
  public UserSelectedMonth: string;
  public UserSelectedDay: string;
  public UserSelectedHour: string;
  public UserSelectedMinute: string;
  public MaxDate: Date = new Date(this.TodaysDate.setFullYear(this.TodaysDate.getFullYear() + 1)); //Max date is one year in the future
  public CalendarUsed: boolean = false;

  //Variables required for "use connection address for mailing address option:"
  public CopyAddress: boolean = false;
  public ReuseConnectionAddress: boolean = false;
  public MailingAddressStreetNumber: string = "";
  public MailingAddressStreetName: string = "";
  public MailingAddressApartmentNumber: string = "";
  public MailingAddressCity: string = "";
  public MailingAddressCountry: string = "";
  public MailingAddressProvince: string = "";
  public MailingAddressPostalCode: string = "";
  public RequestCivicPowerStreetNumber: string = "";
  public RequestCivicPowerStreetName: string = "";
  public RequestCivicPowerApartmentNumber: string = "";
  public RequestCivicPowerCity: string = "";
  public RequestCivicPowerCountry: string = "";
  public RequestCivicPowerProvince: string = "";
  public RequestCivicPowerPostalCode: string = "";

  public datePickerOptions = {
    disableWeekends: true, //no service on weekends
    inline: true,
    firstDayOfWeek: 'su',
    markCurrentDay: true,
    disableUntil: { year: this.TodaysDate.getFullYear() - 1, month: this.TodaysDate.getMonth() + 1, day: this.TodaysDate.getDate() },
    dateFormat: "yyyy-mm-dd hh:mn mr"
  }

  public onDateChanged(event) {
    this.UserSelectedYear = event.date.year;
    if (event.date.month < 10) { this.UserSelectedMonth = "0" + event.date.month } else { this.UserSelectedMonth = event.date.month };
    if (event.date.day < 10) { this.UserSelectedDay = "0" + event.date.day } else { this.UserSelectedDay = event.date.day };
    this.CalendarUsed = true;
    //if (event.date.hours < 10) { this.UserSelectedHour = "0" + event.date.hours } else { this.UserSelectedHour = event.date.hours };
    //if (event.date.meridian == "pm" || event.date.meridian == "PM") { this.UserSelectedHour = 12 + event.date.hours; }
    //if (event.date.minutes < 10) { this.UserSelectedMinute = "0" + event.date.minutes } else { this.UserSelectedMinute = event.date.minutes };
  }

  public useSameAddress(copyAddress: boolean) {
    this.CopyAddress = copyAddress;

    if (this.CopyAddress == true)
    {
      this.MailingAddressStreetNumber = this.RequestCivicPowerStreetNumber;
      this.MailingAddressStreetName = this.RequestCivicPowerStreetName;
      this.MailingAddressApartmentNumber = this.RequestCivicPowerApartmentNumber;
      this.MailingAddressCity = this.RequestCivicPowerCity;
      this.MailingAddressCountry = this.RequestCivicPowerCountry;
      this.MailingAddressProvince = this.RequestCivicPowerProvince;
      this.MailingAddressPostalCode = this.RequestCivicPowerPostalCode;
      this.ReuseConnectionAddress = true;
    }
    else
    {
      this.ReuseConnectionAddress = false;
    }
  }

  ngOnInit() {
    this.ResetServerFormErrors();
  }

  public SendToApi(formP: NgForm): void {  //2019-02-26 01:00:00.000

    //If form is invalid, we exit.
    if (formP.invalid) {
      return;
    }

    formP.value.MailingAddressStreetNumber = this.MailingAddressStreetNumber;
    formP.value.MailingAddressStreetName = this.MailingAddressStreetName;
    formP.value.MailingAddressApartmentNumber = this.MailingAddressApartmentNumber;
    formP.value.MailingAddressCity = this.MailingAddressCity;
    formP.value.MailingAddressCountry = this.MailingAddressCountry;
    formP.value.MailingAddressProvince = this.MailingAddressProvince;
    formP.value.MailingAddressPostalCode = this.MailingAddressPostalCode;

    formP.value.requestCivicPowerStreetNumber = this.RequestCivicPowerStreetNumber;
    formP.value.requestCivicPowerStreetName = this.RequestCivicPowerStreetName;
    formP.value.requestCivicPowerApartmentNumber = this.RequestCivicPowerApartmentNumber;
    formP.value.requestCivicPowerCity = this.RequestCivicPowerCity;
    formP.value.requestCivicPowerCountry = this.RequestCivicPowerCountry;
    formP.value.RequestCivicPowerPostalCode = this.RequestCivicPowerPostalCode;

    formP.value.connection_request_date = this.UserSelectedYear + "-" + this.UserSelectedMonth + "-" + this.UserSelectedDay + " 11:00:00.000 AM";  //format the date in such a way that SQL Server can handle it

    formP.value.PhoneNumber = formP.value.PhoneNumber.replace(/\D/g, ''); //strip all non-numerical characters

    //Send Form Results to FormsService
    this.formsService.NewService(formP.value).subscribe((disconnectResultP: FormModels.NewService) => {

      this.ActiveServerRequest = false;

      if (disconnectResultP.IsSuccess) {
        this.router.navigate(['/forms/success']);
      }

      if (disconnectResultP.IsFailure) {
        this.router.navigate(['/forms/error']);
      }

      this.NewServiceErrorMessages = disconnectResultP.ErrorMessages;

    }, (errorP) => {
      this.ActiveServerRequest = false;
      this.ServerConnectionError = true;
      this.router.navigate(['/forms/error']);
      return;
    });
  }

  private ResetServerFormErrors(): void {
    this.NewServiceErrorMessages = [];
    this.ServerConnectionError = false;
  }
}
