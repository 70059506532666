﻿import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { FormsService } from "../../services/forms.service";
import { FormModels } from "../../models/api/form-models";
import { Router } from "@angular/router";

@Component({
  selector: 'mp-request-donation',
  templateUrl: './request-donation.component.html',
  styleUrls: ['./request-donation.component.scss']
})
export class RequestDonationComponent implements OnInit {

  constructor(private formsService: FormsService,
    private router: Router) { }

  public RequestDonationErrorMessages: string[];
  public ServerConnectionError: boolean;
  public ActiveServerRequest: boolean;


  ngOnInit() {
    this.ResetServerFormErrors();
  }

  public SendToApi(formP: NgForm): void {

    //If form is invalid, we exit.
    if (formP.invalid) {
      return;
    }

    //Send Form Results to FormsService
    this.formsService.RequestDonation(formP.value).subscribe((requestDonationResultP: FormModels.RequestDonation) => {
      this.ActiveServerRequest = false;

      if (requestDonationResultP.IsSuccess) {
        this.router.navigate(['/forms/success']);
      }

      this.RequestDonationErrorMessages = requestDonationResultP.ErrorMessages;

    }, (errorP) => {
      this.ActiveServerRequest = false;
      this.ServerConnectionError = true;
      this.router.navigate(['/forms/error']);
      return;
    });
  }

  private ResetServerFormErrors(): void {
    this.RequestDonationErrorMessages = [];
    this.ServerConnectionError = false;
  }
}

